.liffMessenger{
    position: fixed;
    right: 1%;
    bottom: 2%;
    width: 4%;
    min-width: 50px;
    cursor: pointer;
}
.liffMessenger:hover{
    right: 0.6%;
    bottom: 1.6%;
    width: 5%;
    min-width: 55px;
    cursor: pointer;
}
.btnDiv{
    display: flex;
    justify-content: center;
}
.btnDiv button{
    width: 20%;
    margin: 10px;
}
.btnDiv .btnLogout{
    background-color: #e3a13f!important;
}
@media screen and (max-width: 767px) {
    .btnDiv button{
        width: 32%;
        padding: 5px 3px;
        margin: 0 8px 8px;
    }    
}