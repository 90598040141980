.wpReadParentChildCourseLayout{
    padding: 5px;
}
.wpReadParentChildCourseForm{
    margin: 8px auto 0;
    padding: 15px 40px 0;
    /* max-width: 435px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 80vh; */
}
.wpReadParentChildCourseFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpReadParentChildCourseH4 {
    font-weight: 600;
    margin-bottom: 1.5rem;
    /* margin: 1rem 0; */
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpReadParentChildCourseH6 {
    /* margin: 1px 0 8px; */
    margin: 0;
    flex: 2;
    /* min-width: 83px; */
    text-align: left;
}
.wpReadParentChildCourseSpaceSendBtn{
    flex: 9;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    /* min-width: 370px; */
    padding-right: 10px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.wpReadParentChildCourseSpaceDropdown{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
/* .wpReadParentChildCourseSpaceDropdown h6{
    margin-bottom: 1px!important;
} */
.wpReadParentChildCourseDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpReadParentChildCourseFlex1{
    flex: 2;
    text-align: left;
}
.wpReadParentChildCourseFlex2{
    flex: 9;
    text-align: left;
}
.wpReadParentChildCourseSpaceTime{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpReadParentChildCourseRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpReadParentChildCourseRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpReadParentChildCourseRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpReadParentChildCourseRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpReadParentChildCourseRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpReadParentChildCourseDivTimePicker{
    display: flex;
    flex-wrap: wrap;
}
.wpReadParentChildCourseInputPlace{
    margin-right: 8px;
    /* min-width: 150px; */
    padding: 4px 15px;
}
.wpReadParentChildCourseDatePicker{
    margin-right: 8px;
    /* min-width: 150px; */
    padding: 4px 15px;
}
.wpReadParentChildCourseTimePicker{
    margin-right: 8px;
    /* min-width: 250px; */
    padding: 4px 15px;
}
.wpReadParentChildCourseFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpReadParentChildCourseSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpReadParentChildCourseSpaceCheckbox{
    display: flex;
}
.wpReadParentChildCourseSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpReadParentChildCourseFormItemLineCastInterface{
    width: 100%;
}
.wpReadParentChildCourseSpaceLineCastInterface{
    width: 100%;
}
.wpReadParentChildCourseSpaceLineCastInterface > div{
    width: 100%;
}
.wpReadParentChildCourseFilterConsultContent{
    margin-bottom: 2em!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
/* .wpReadParentChildCourseFilterConsultContent h1, .wpReadParentChildCourseFilterConsultContent h2, .wpReadParentChildCourseFilterConsultContent h3, .wpReadParentChildCourseFilterConsultContent h4, .wpReadParentChildCourseFilterConsultContent h5, .wpReadParentChildCourseFilterConsultContent h6{
    margin-bottom: .6em;
} */
.wpReadParentChildCourseFilterConsultContent div, .wpReadParentChildCourseFilterConsultContent span, .wpReadParentChildCourseFilterConsultContent p {
    font-size: 16px;
}
.wpReadParentChildCourseFilterConsultContentQuery h5{
    /* color: #1677ff; */
    font-weight: 800;
}
.wpReadParentChildCourseFilterConsultSearchSec{
    width: 80%;
    max-width: 875px;
    min-width: 875px;
    height: 32vh;
    margin: 10px auto;
    padding: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 3px -1px 10px -1px #00152933;
    border-radius: 3px;
    background-color: #F9F9F9;
}
.wpReadParentChildCourseFilterConsultTitle h4{
    font-weight: 800;
    color: #001529;
}
.wpReadParentChildCourseFilterConsultList{
    width: 100%;
    /* border: 1px solid #1677ff; */
    border-radius: 5px;
    padding: 10px;
}
.wpReadParentChildCourseFilterConsultList ul{
    max-height: 20vh;
    overflow-y: scroll;
    padding: 0 12px!important;
}
/* .wpReadParentChildCourseFilterConsultList span{
    color: #1677ff;
} */
.wpReadParentChildCourseFilterConsultListItemMeta{
    flex-wrap: wrap;
    flex-direction: column;
    /* cursor: pointer; */
}
/* .wpReadParentChildCourseFilterConsultListItemMeta:hover{
    background-color: #dedede;
} */
.wpReadParentChildCourseFilterConsultCourseDescriptionDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemName {
    width: 240px;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemCategory{
    width: 300px;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemDescription{
    width: 360px;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemPriority{
    width: 145px;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleID{
    flex: 1;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleName{
    flex: 2;
    overflow: hidden;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleID, .wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleName, .wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleLocation, .wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleStartTime, .wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleEndTime {
    min-width: 225px;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionText{
    font-weight: 400;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    /* color: #333; */
}

.wpReadParentChildCourseFilterConsultCourseDescriptionLabel{
    font-weight: 800;
    color: #001529cc;
    text-align: left;
    white-space: nowrap;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionName{
    font-weight: 800;
    text-align: left;
    white-space: nowrap;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionLabelName, .wpReadParentChildCourseFilterConsultCourseDescriptionLabelCategory, .wpReadParentChildCourseFilterConsultCourseDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.wpReadParentChildCourseFilterConsultDiv{
    display: flex;
    flex-direction: row;
}
.wpReadParentChildCourseFilterConsultIcon{
    cursor: pointer;
    /* margin: 2px 1px 5px 5px!important; */
    margin: 2px;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpReadParentChildCourseFilterConsultIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleDiv{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.wpReadParentChildCourseFilterConsultIconDiv{
    display: flex;
    margin: 8px 4px;
    justify-content: flex-end;
}
.wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv{
    width: 100%;
    max-width: 690px;
    display: flex;
    flex-wrap: wrap;
}
.wpReadParentChildCourseFilterConsultCourseDivUpdateBtn{
    /* width: 100%; */
    display: flex;
    justify-content: center;
}
.wpReadParentChildCourseFilterConsultCourseUpdateBtn span{
    font-size: 14px;
}
@media screen  and (max-width: 1510px) {
    .wpReadParentChildCourseFilterConsultList ul{
        max-height: 30vh;
    }
    .wpReadParentChildCourseFilterConsultSearchSec{
        height: 40vh;
    }
}
@media screen  and (max-width: 1178px) {
    .wpReadParentChildCourseFilterConsultCourseDescriptionDiv{
        justify-content: flex-start;
    }
}
@media screen  and (max-width: 1090px) {
    .wpReadParentChildCourseFilterConsultSearchSec{
        min-width: initial;
    }
}
@media screen  and (max-width: 1047px) {
    .wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleDiv{
        align-items: flex-start;
    }     
    .wpReadParentChildCourseFilterConsultIconDiv{
        width: 100%;
    }
}
@media screen  and (max-width: 767px) {
    .wpReadParentChildCourseForm{
        padding: 10px 20px;
    }
    .wpReadParentChildCourseFilterConsultContent div, .wpReadParentChildCourseFilterConsultContent span, .wpReadParentChildCourseFilterConsultContent p {
        font-size: 14px;
    }
    .wpReadParentChildCourseFilterConsultContentQuery h5{
        font-size: 18px;
    }
    .wpReadParentChildCourseFilterConsultSearchSec{
        width: 100%;
    }
    .wpReadParentChildCourseFilterConsultList{
        padding: 0px 8px;
    }
    .wpReadParentChildCourseFilterConsultCourseDescriptionDiv{
        width: 100%;
        /* flex-direction: column; */
    }
    .wpReadParentChildCourseFilterConsultCourseDescriptionItemTitleDiv{
        flex-direction: column-reverse;
    }    
    .wpReadParentChildCourseFilterConsultCourseDescriptionItem {
        margin: 8px 4px;
        /* width: 100%; */
        /* justify-content: flex-start; */
    }
    .wpReadParentChildCourseFilterConsultCourseDescriptionItemUpdateBtn{
        justify-content: center;
    }
    /* .wpReadParentChildCourseH4 {
        padding-top: 15px;
    } */
    /* .wpReadParentChildCourseSpaceTime{
        min-width: 280px;
    } */
    .wpReadParentChildCourseFilterConsultSearchSec{
        padding: 18px 12px;
        height: 100%;
    }
    .wpReadParentChildCourseFilterConsultIconDiv{
        justify-content: flex-start;
    }

}
@media screen  and (max-width: 550px) {
    .wpReadParentChildCourseFilterConsultCourseDivUpdateBtn{
        margin-left: 43%;
    }
}
@media screen  and (max-width: 540px) {
    .wpReadParentChildCourseForm{
        margin: initial;
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .wpReadParentChildCourseDatePicker{
        margin-bottom: 8px;
    }
}
