.Navbar {
  display: inline-block;
}
.NavbarCollapse{
  display: none;
}
.Navigationbar{
    margin-right: 1rem;
}
.Navbar_search{
    border: none;
    background-color: #EBEBEB;
}
.position_relative{
    background-color: #EBEBEB;
  
}
.Navbar_search_img{
    padding-top: .5rem;
    background-color: #EBEBEB;
}
.Navbar_promote{
    width: 100%;
    background-color: #1E8C96;
    color: #FFFFFF;
}
.Navbar_promote{
    text-align: center;
}

/* RWD 電腦版 */
.kdlogobox {
  display: inline-block;
}
.smalllogobox {
  display: none;
}
.kdlogo {
  width: 100%;
}
.smalllogo {
  width: 100%;
}
/* END RWD 電腦版 */

/* RWD 手機版 */
@media screen and ( max-width: 540px ) {
  .kdlogobox {
    display: none;
  }
  .smalllogobox {
    display: inline-block;
  }
}
/* END RWD 手機版 */




