.usersReadLayout{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
}
.usersReadOuterContent{
    display: flex;
    /* align-items: center; */
    overflow-y: hidden!important;
}
.usersReadContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.usersReadContent h1, .usersReadContent h2, .usersReadContent h3, .usersReadContent h4, .usersReadContent h5, .usersReadContent h6{
    margin-bottom: .6em;
}
.usersReadContent div, .usersReadContent span, .usersReadContent p {
    font-size: 16px;
}
.usersReadSearchSec{
    width: 80%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: column;
}
.usersReadSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.usersReadSearch{
    width: 100%;
    margin: 1% 0 1.2% 2.5%;
}
.usersReadSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.usersReadSearch svg{
    display: flex;
    align-items: center;
}
.usersReadList{
    width: 100%;
}
.usersReadListItemMeta{
    flex-wrap: wrap;
}
.usersReadInputSpace{
    margin: 0.5em;
}
.usersReadUserDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.usersReadUserEditAndDelIconDiv{
    display: flex;
    flex-direction: row;
}
.usersReadUserEditAndDelIcon{
    cursor: pointer;
    margin: 2px 1px 1px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.usersReadUserEditAndDelIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.usersReadUserDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.usersReadUserDescriptionItemName {
    width: 200px;
}
.usersReadUserDescriptionItemCategory{
    width: 235px;
}
.usersReadUserDescriptionItemDescription{
    width: 320px;
}
.usersReadUserDescriptionItemPriority{
    width: 125px;
}
.usersReadUserDescriptionItemUpdateBtn{
    width: 100%;
}
.usersReadUserDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.usersReadUserDescriptionLabel{
    font-weight: 800;
    color: #777;
}
.usersReadUserDescriptionLabelName, .usersReadUserDescriptionLabelCategory, .usersReadUserDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.usersReadUserDescriptionLabelPriority{
    /* flex: 4; */
    width: 70px;
}
.usersReadUserDescriptionInputName{
    /* flex: 2; */
    width: 100px;
}
.usersReadUserDescriptionInputCategory{
    /* flex: 3; */
    width: 135px;
}
.usersReadUserDescriptionInputDescription{
    /* flex: 4; */
    width: 220px;
}
.usersReadUserDescriptionInputPriority{
    /* flex: 0.5; */
    width: 55px;
}
@media screen and (max-width: 767px) {
    .usersReadSearchSec{
        width: 100%;
    }
    .usersReadSearch{
        width: 70%;
        min-width: 280px;
    }
    .usersReadUserDescriptionDiv{
        flex-direction: column;
    }
    .usersReadUserDescriptionItemDescription{
        width: 260px;
    }
    .usersReadUserDescriptionInputDescription{
        width: 160px;
    }
    .usersReadUserDescriptionItem{
        width: 100%;
    }
    .usersReadUserDescriptionItemTitle{
        flex-direction: column;
    }
    .usersReadUserEditAndDelIcon{
        margin: 5px!important;
    }
}