/* #UsersRead .ant-layout-content {
    overflow-y: hidden;
} */
/* 分頁區塊 */
#UsersRead :where(.css-dev-only-do-not-override-mxhywb).ant-list .ant-list-pagination {
    margin-block-start: 24px;
    margin-block-end: 24px;
}
/* 新增User頁面查詢區塊 */
#UsersAdd .ant-list-item-meta-content {
    width: 100%!important;
}
/* @media screen and (max-width: 767px) {
    #UsersRead .ant-layout-content {
        overflow-y: hidden;
    }
} */