.usersLayout{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
}
.usersOuterContent{
    display: flex;
    /* align-items: center; */
}
.usersContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.usersContent h1, .usersContent h2, .usersContent h3, .usersContent h4, .usersContent h5, .usersContent h6{
    margin-bottom: .6em;
}
.usersContent div, .usersContent span, .usersContent p {
    font-size: 16px;
}
/* .usersSearchSec{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.usersSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.usersSearch{
    width: 94%;
    margin: 0.5em 0em 0.7em 1.2em;
}
.usersSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.usersSearch svg{
    display: flex;
    align-items: center;
}
.usersInputSpace{
    margin: 0.5em;
}
.usersUserDescriptionDiv{
    display: flex;
    justify-content: space-between;
}
.usersUserDescriptionLabel{
    font-weight: 800;
    color: #777;
} */
@media screen and (max-width: 767px) {
    .usersContent{
        margin: 0.5em!important;
    }
}