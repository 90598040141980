.tagsLayout{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
}
.tagsOuterContent{
    display: flex;
    /* align-items: center; */
}
.tagsContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.tagsContent h1, .tagsContent h2, .tagsContent h3, .tagsContent h4, .tagsContent h5, .tagsContent h6{
    margin-bottom: .6em;
}
.tagsContent div, .tagsContent span, .tagsContent p {
    font-size: 16px;
}
/* .tagsSearchSec{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.tagsSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.tagsSearch{
    width: 94%;
    margin: 0.5em 0em 0.7em 1.2em;
}
.tagsSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.tagsSearch svg{
    display: flex;
    align-items: center;
}
.tagsInputSpace{
    margin: 0.5em;
}
.tagsTagDescriptionDiv{
    display: flex;
    justify-content: space-between;
}
.tagsTagDescriptionLabel{
    font-weight: 800;
    color: #777;
} */
@media screen and (max-width: 767px) {
    .tagsContent{
        margin: 0.5em!important;
    }
}