.chatRoomAssign{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chatRoomAssignH6{
    margin: 0;
    margin-left: 12%;
    width: 100%;
    color: #FFF;
    flex: 4;
    font-size: 1rem;
}
.chatRoomAssignButton{
    border-radius: 5px;
    flex: 1;
}
.chatRoomAssignDropdown button{
    background-color: #1677FE!important;
    border-radius: 5px;
}
.chatRoomAssignDropdown button:hover{
    background-color: #4592FE!important;
    opacity: 1;
}
.chatRoomAssignButton:hover{
    background-color: rgb(110, 230, 110)!important;
    opacity: 1!important;
}
@media screen and (max-width: 1240px) {
    .chatRoomAssignH6{
        flex: 3;
    }
    .chatRoomAssignButton{
        flex: 1;
    }
}
@media screen and (max-width: 991px) {
    .chatRoomAssignH6{
        margin-left: 19%;
    }
}
@media screen and (max-width: 767px) {
    .chatRoomAssign{
        height: 50px;
        /* padding: 8px; */
        border-left: initial;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .chatRoomAssignH6{
        flex: 6;
        margin-left: 5%;
        text-align: left;
        font-size: 14px;
    }
    .chatRoomAssignSpan{
        display: none;
        /* font-size: 12px; */
    }
    .chatRoomAssignButton{
        flex: 3;
    }
}