.userListContent{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.userListChatItemDiv{
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
}
.userListButton{
    width: 88px;
    height: 72px;
    border: 1px solid #FFF!important;
    border-color: #F5F5F5!important;
    border-radius: 0 5px 5px 0!important;
    align-items: center;
}
.infoDiv{
    margin:auto;
    text-align: left;
}
.infoSpan{
    text-align: left;
    padding: 1px;
    color: #f26419;
}
@media screen and (max-width: 991px) {
    .userListChatItemDiv{
        justify-content: center;
    }
}
@media screen and (max-width: 767px) {
    .userListButton{
        width: 100%;
        height: initial;
        border-radius: 0 0 5px 5px!important;
    }
    .userListChatItemDiv{
        flex-direction: column;
    }
}