.wpSetAbilityForm{
    /* margin: 8px auto; */
    /* padding: 20px 40px; */
    /* max-width: 505px; */
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* min-height: 80vh; */
}
.wpSetAbilityContent{
    /* margin: 8px auto!important; */
    /* max-width: 505px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 292px; */
}
.wpSetAbilityContent>section{
    margin: 13px auto 8px;
    max-width: 445px;
    width: 94%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.wpSetAbilityOuterSection{
    width: 100%;
    max-width: 1024px!important;
    display: flex;
    flex-direction: row!important;
    flex-wrap: wrap;
    justify-content: center;
}
.wpSetAbilityOuterSectionAddClass{
    margin: 0 auto!important;
}
.wpSetAbilitySection{
    width: 100%;
    /* padding: 0 40px; */
}
.wpSetAbilityBtnSection{
    flex-direction: row!important;
    max-width: fit-content!important;
}
.wpSetAbilitySectionTitle{
    padding-top: 15px;
}
.wpSetAbilitySectionCourseIdName .wpSetAbilityFormItem{
    margin-bottom: 1rem!important;
}
.wpSetAbilitySectionCourseIdName .wpSetAbilityDivNameAndAddBtn  .wpSetAbilityFormItem{
    margin-bottom: 0!important;
}
.wpSetAbilitySectionAddAbility, .wpSetAbilitySectionAddItem, .wpSetAbilitySectionAddActivity{
    max-width: 460px;
    padding: 10px 20px;
}
.wpSetAbilityDivAbility, .wpSetAbilityDivItem, .wpSetAbilityDivActivity{
    padding: 15px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    background-color: #FAFAFA;
}
/* .wpSetAbilitySection:last-child{
    padding-bottom: 20px;
} */
.wpSetAbilityFormItem{
    width: 100%;
    max-width: 540px;
    /* margin: auto!important; */
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpSetAbilityDivAbility .wpSetAbilityFormItem, .wpSetAbilityDivItem .wpSetAbilityFormItem, .wpSetAbilityDivActivity .wpSetAbilityFormItem{
    margin-bottom: 0.6rem!important;
}
.wpSetAbilityFormItemCompensate, .wpSetAbilityFormItemName, .wpSetAbilityFormItemAddClass{
    margin: 0!important;
}
.wpSetAbilityFormItemCompensate, .wpSetAbilityFormItemName{
    /* margin-right: 8px!important; */
    flex: 5;
    display: flex;
    align-items: center;
}
.wpSetAbilityFormItemAddClass{
    flex: 3;
}
.wpSetAbilityFormItemSendBtn{
    margin: auto!important;
    width: initial;
}
.wpSetAbilityH4 {
    font-weight: 600;
    /* margin-bottom: .6rem; */
    margin-bottom: 0;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpSetAbilityH6 {
    /* margin: 1px 0 8px; */
    margin: 0 8px 0 0;
    flex: 2;
    /* min-width: 95px; */
    text-align: left;
}
.wpSetAbilityDivNameAndAddBtn{
    width: 100%;
    /* margin-bottom: 1rem; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpSetAbilitySpaceSendBtn{
    flex: 9;
    width: 100%;
    margin-top: .5rem;
    /* margin-bottom: 0.3rem; */
    /* min-width: 370px; */
    /* padding-right: 10px; */
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.wpSetAbilitySpaceUpdateBtn{
    margin: 8px 8px 8px 150px;
}
.wpSetAbilitySpaceUpdateBtnText div{
    font-size: 14px!important;
}
/* .wpSetAbilitySpaceSendBtn button{
    height: initial;
}
.wpSetAbilitySpaceSendBtn button div{
    font-size: initial;
} */
.wpSetAbilitySpaceSendBtnAdd{
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;
}
/* .wpSetAbilitySpaceSendBtnAdd button div{
    font-size: 14px;
    padding: 2px 1px;
} */
.wpSetAbilityOuterDivInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetAbilitySpaceInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetAbilitySpaceInputFormal{
    flex-wrap: nowrap;
}
.wpSetAbilityInputFormal{
    margin-right: 8px;
}
.wpSetAbilityDivDeleteOutlined{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.wpSetAbilityDelIcon{
    cursor: pointer;
    margin: 2px 1px 1px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpSetAbilityDelIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
/* .wpSetAbilitySpaceInputName{
    flex-wrap: wrap;
    align-items: initial;
} */
/* .wpSetAbilityDivInput{
    display: flex;
    flex-wrap: wrap;
} */
.wpSetAbilityInput{
    width: 324px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpSetAbilityInputType{
    margin-top: 8px;
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetAbilityInputDescription{
    margin-top: 8px;
    width: 324x;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetAbilityInputMonthRange{
    width: 300px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpSetAbilityInputMonthRangeUpdate{
    margin-left: 30px;
}
.wpSetAbilityInputName{
    width: 340px;
    min-width: 250px;
    padding: 4px 15px;
}
.wpSetAbilityInputKidCount{
    width: 324px;
    min-width: 50px;
    padding: 4px 15px;
    border-radius: 5px;
}
.wpSetAbilityInputPeriod{
    width: 80px;
    min-width: 80px;
    padding: 4px 0;
    border-radius: 5px;
}
.wpSetAbilityInputItem{
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
    border-radius: 5px;
}
.wpSetAbilityInputIntro{
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetAbilityInputIntro.wpSetAbilityInputIntroUpdate{
    width: 100%;
}
.wpSetAbilityInputPrice{
    width: 120px;
    min-width: 120px;
    padding: 4px 15px;
}
.wpSetAbilityInputPriceUpdate{
    max-width: 180px;
}
.wpSetAbilityInputPlace{
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpSetAbilityInputMaximum{
    width: 80px;
    min-width: 80px;
    padding: 4px 15px;
}
.wpSetAbilityDropdownCategory{
    width: 324px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetAbilityDropdownType, .wpSetAbilityDropdownTopic, .wpSetAbilityDropdownProject {
    width: 324px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetAbilityDropdownBtnSpace{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
/* .wpSetAbilitySpaceDropdown{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} */
/* .wpSetAbilitySpaceDropdown h6{
    margin-bottom: 1px!important;
} */
.wpSetAbilityDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpSetAbilityFlex1{
    flex: 2;
    text-align: left;
}
.wpSetAbilityFlex2{
    flex: 9;
    text-align: left;
}
.wpSetAbilitySpaceTime{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetAbilityRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpSetAbilityRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpSetAbilityRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpSetAbilityRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpSetAbilityRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpSetAbilityDivTimePicker{
    display: flex;
    flex-wrap: wrap;
}
.wpSetAbilityDatePicker{
    /* margin-right: 8px; */
    width: 150px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpSetAbilityTimePicker{
    /* margin-right: 8px; */
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpSetAbilityFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpSetAbilitySpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpSetAbilitySpaceCheckbox{
    display: flex;
}
.wpSetAbilitySpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpSetAbilityFormItemLineCastInterface{
    width: 100%;
}
.wpSetAbilitySpaceLineCastInterface{
    width: 100%;
}
.wpSetAbilitySpaceLineCastInterface > div{
    width: 100%;
}
.wpSetAbilityFilterConsultContent{
    margin: 0.8rem 0 2em!important;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
/* .wpSetAbilityFilterConsultContent h1, .wpSetAbilityFilterConsultContent h2, .wpSetAbilityFilterConsultContent h3, .wpSetAbilityFilterConsultContent h4, .wpSetAbilityFilterConsultContent h5, .wpSetAbilityFilterConsultContent h6{
    margin-bottom: .6em;
} */
.wpSetAbilityFilterConsultContent div, .wpSetAbilityFilterConsultContent span, .wpSetAbilityFilterConsultContent p {
    font-size: 16px;
}
.wpSetAbilityFilterConsultContentQuery h5{
    /* color: #1677ff; */
    font-weight: 800;
}
.wpSetAbilityFilterConsultSearchSec{
    width: 80%;
    max-width: 875px;
    min-width: 875px;
    height: 50vh;
    margin: 10px auto;
    padding: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 3px -1px 10px -1px #00152933;
    border-radius: 3px;
    background-color: #F9F9F9;
}
.wpSetAbilityFilterConsultTitle h4{
    font-weight: 800;
    color: #001529;
}
.wpSetAbilityFilterConsultList{
    width: 100%;
    /* border: 1px solid #1677ff; */
    border-radius: 5px;
    padding: 10px;
}
.wpSetAbilityFilterConsultList ul{
    max-height: 47vh;
    overflow-y: scroll;
    padding: 0 12px!important;
}
/* .wpSetAbilityFilterConsultList span{
    color: #1677ff;
} */
.wpSetAbilityFilterConsultListItemMeta{
    flex-wrap: wrap;
    flex-direction: column;
    /* cursor: pointer; */
}
/* .wpSetAbilityFilterConsultListItemMeta:hover{
    background-color: #dedede;
} */
.wpSetAbilityFilterConsultCourseDescriptionDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wpSetAbilityFilterConsultCourseDescriptionPanel .wpSetAbilityFilterConsultCourseDescriptionQueryDiv{
    border-bottom: 1px solid #ddd;
}
.wpSetAbilityFilterConsultCourseDescriptionPanel .wpSetAbilityFilterConsultCourseDescriptionQueryDiv:last-child{
    border-bottom: initial;
}
.wpSetAbilityFilterConsultCourseDescriptionItem{
    /* width: 100%; */
    margin: 8px;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: flex-start;
}
.wpSetAbilityFilterConsultCourseDescriptionItemName {
    width: 240px;
}
.wpSetAbilityFilterConsultCourseDescriptionItemCategory{
    width: 300px;
}
.wpSetAbilityFilterConsultCourseDescriptionItemDescription{
    width: 360px;
}
.wpSetAbilityFilterConsultCourseDescriptionItemPriority{
    width: 145px;
}
.wpSetAbilityFilterConsultCourseDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.wpSetAbilityFilterConsultCourseDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.wpSetAbilityFilterConsultCourseDescriptionText{
    width: 240px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.wpSetAbilityFilterConsultCourseDescriptionLabel{
    font-weight: 800;
    color: #001529cc;
}
.wpSetAbilityFilterConsultCourseDescriptionName{
    margin-right: 8px;
    font-weight: 800;
    text-align: left;
    white-space: nowrap;
}
.wpSetAbilityFilterConsultCourseDescriptionLabelName, .wpSetAbilityFilterConsultCourseDescriptionLabelCategory, .wpSetAbilityFilterConsultCourseDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.wpSetAbilityFilterConsultCourseDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.wpSetAbilityFilterConsultDiv{
    display: flex;
    flex-direction: row;
}
.wpSetAbilityFilterConsultCourseDescriptionItemTitleIdNameDiv{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.wpSetAbilityFilterConsultIconDiv{
    margin: 8px 4px;
    display: flex;
    justify-content: flex-end;
}
.wpSetAbilityFilterConsultIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpSetAbilityFilterConsultIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.wpSetAbilityFilterConsultCourseDescriptionItemTitleDiv{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.wpSetAbilityFilterConsultCourseDescriptionItemTitleDivUpdate{
    flex-direction: column;
    align-items: flex-start;
}
.wpSetAbilityFilterConsultCourseDescriptionItemTitle{
    max-width: 315px;
    min-width: 250px;
    /* max-width: 70%; */
}

.wpSetAbilityFilterConsultCourseDescriptionItemTitleIntro{
    max-width: initial;
    width: 100%;
}
.wpSetAbilityFilterConsultCourseDescriptionItemTitleDivUpdate{
    align-items: flex-start;
    flex-direction: row;
}
.wpSetAbilityFilterConsultCourseDescriptionItemTitleIdNameDivUpdate{
    flex-direction: column;
}
.wpSetAbilityDivInputName, .wpSetAbilityDivInputCategory{
    display: flex;
    flex-direction: column;
}
.wpSetAbilityDivSlider{
    width: 100%;
}

@media screen and (max-width: 1510px) {
    .wpSetAbilityFilterConsultSearchSec{
        height: 57vh;
    }
}
@media screen and (max-width: 1220px) {
    .wpSetAbilityFilterConsultCourseDescriptionItemTitleDiv{
        align-items: flex-start;
    }
    .wpSetAbilityFilterConsultCourseDescriptionText{
        width: initial;
    }
}
@media screen and (max-width: 1106px) {
    .wpSetAbilityOuterSection{
        justify-content: center;
    }
}
@media screen and (max-width: 1090px) {
    .wpSetAbilityFilterConsultSearchSec{
        min-width: initial;
    }
}
@media screen  and (max-width: 900px) {
    .wpSetAbilityFilterConsultCourseDescriptionItemTitleIdNameDiv{
        max-width: 335px;
    }
    .wpSetAbilityFilterConsultCourseDescriptionItemTitle{
        max-width: 95%;
    }
}
@media screen  and (max-width: 767px) {
    .wpSetAbilityContent>section{
        margin: 8px 0;
    }
    .wpSetAbilityH4 {
        margin-top: .3rem;
    }
    .wpSetAbilityFilterConsultIcon{
        margin: 2px 5px 2px 1px!important;
    }
    .wpSetAbilityForm{
        /* padding: 10px 20px; */
        padding: 5px; 
    }
    .wpSetAbilityFilterConsultContent div, .wpSetAbilityFilterConsultContent span, .wpSetAbilityFilterConsultContent p {
        font-size: 14px;
    }
    /* .wpSetAbilityFilterConsultContentQuery{
        min-width: 292px;
    } */
    .wpSetAbilityFilterConsultContentQuery h5{
        font-size: 18px;
    }
    .wpSetAbilityFilterConsultSearchSec{
        width: 100%;
        /* min-width: 292px; */
    }
    .wpSetAbilityFilterConsultList{
        padding: 0px 8px;
    }
    .wpSetAbilityFilterConsultCourseDescriptionDiv{
        width: 100%;
        /* flex-direction: column; */
    }
    .wpSetAbilityFilterConsultCourseDescriptionItem {
        margin: 8px 4px;
        /* width: 100%; */
        /* justify-content: flex-start; */
    }
    .wpSetAbilityFilterConsultCourseDescriptionItemUpdateBtn{
        justify-content: center;
    }
    .wpSetAbilityContent{
        max-width: initial;
    }
    .wpSetAbilitySection{
        /* min-width: 300px; */
        padding: 0;
    }
    /* .wpSetAbilitySectionCourseIdName{
        padding: 0 20px 0;
    } */
    .wpSetAbilitySectionAddActivity{
        padding: 0.6rem 0 0;
    }
    /* .wpSetAbilityDivActivity{
        min-width: 292px;
    } */
    .wpSetAbilityDelIcon{
        margin: 5px!important;
    }
    .wpSetAbilityInputPlace{
        width: 100%;
        min-width: 252px;
    }
    .wpSetAbilityTimePicker{
        width: 100%;
        min-width: 252px;
    }
    .wpSetAbilityDropdownCategory{
        /* width: 100%; */
        min-width: 252px;
    }
    /* .wpSetAbilityDropdownType, .wpSetAbilityDropdownTopic, .wpSetAbilityDropdownProject {
        min-width: 252px;
    } */
    .wpSetAbilityInputKidCount{
        width: 80px!important;
        min-width: 80px;
    }
    .wpSetAbilityInputPeriod{
        width: 80px!important;
        min-width: 80px;
    }
    .wpSetAbilityInputMonthRange{
        width: 100%;
    }
    .wpSetAbilityFilterConsultCourseDescriptionItemTitleDiv{
        flex-direction: column-reverse;
    }
    /* .wpSetAbilitySpaceTime{
        min-width: 280px;
    } */
    .wpSetAbilityFilterConsultSearchSec{
        height: 100%;
        padding: 18px 12px;
    }
    .wpSetAbilitySpaceInputPeriod{
        width: 85%;
    }
}
@media screen  and (max-width: 540px) {
    /* .wpSetAbilityForm{
        padding: 5px;
    } */
    .wpSetAbilityInput{
        width: 100%;
    }
    .wpSetAbilityInputName{
        width: 100%;
    }
    .wpSetAbilityDropdownCategory{
        width: 100%;
        /* min-width: 150px; */
    }
    .wpSetAbilityDropdownType, .wpSetAbilityDropdownTopic, .wpSetAbilityDropdownProject {
        width: 100%;
    }
    .wpSetAbilityFilterConsultCourseDescriptionText{
        max-width: 125px;
    }
}
@media screen  and (max-width: 431px) {
    /* .wpSetAbilityDatePicker{
        margin-bottom: 8px;
    } */
}
