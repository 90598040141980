
.loginbuttosec{ 
    text-align: center;
}
.inputinfiled{
    width: 100%;
    border:none;
    border-bottom: 1px ;
    outline: none;
    border-bottom: 1px solid #585858;
}

/* .loginbutton{
    background-color: #1E8C96;
    border: none;
    width: 15rem;
    color: white;
} */
.loginbutton{ 
    background-color: #1E8C96;
    border: none;
    border-radius: 3px;
    color: #fff;
    padding: 0.5em;
    width: 95%;
}
.loginbutton:hover{
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
.form{
    background-color: white;
    padding:  0 2rem;
}
.KidProLoginTitleBox{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    width: 100%;
}
.KidProLoginMarkBox{
    /* display: inline;
    width: 3rem; */
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.KidProLoginMark{
    width: 100%;
    height: 100%;
    flex: 4;
}
.KidProLoginMainTitle{
    flex: 10;
    font-size: 1.6rem;
    margin: 1rem;
    color: #1E8C96;
    font-weight: bold;
}
.KidProLoginTitle{
    font-size: 1rem;
    color: black;
    margin: 1rem;
}

.loginWayDirectMailSec{
    display: inline-block;
    width: 79%;
    text-align: end;
    cursor: pointer;
}
.loginWayDirectPhoneSec{
    display: inline-block;
    width: 65%;
    text-align: end;
    cursor: pointer;

}
.loginWayDirect{
    text-align: end;
    color: #1E8C96;
}
.directSec{
    width: 10rem;
    display: flex;
    margin:  0 auto;
    justify-content: space-around;
}
.padforgotSec a{
    color: #1E8C96;
}
.regSec a {
    color: #FA9600;
}
.mailLable{
    font-size: 1rem;
    font-weight: bold;
    color: #585858;
}
.pdsLable{
    font-weight: bold;
    font-size: 1rem;
    color: #585858;
}
.divLineSec{
    width: 100%;
}
.divLine{
    width: 100%;
}
.socialLoginSec{
    width: 100%;
    display: flex;
    justify-content: center;

}
.SocialIconSec{
    display: flex;
}
.socialLoginTitle {
    margin:  1rem .5rem;
    color: #585858;
    font-weight: bold;
}
.SocialIcon{
    margin-right: .5rem;
}
.countryCode{
  /* border: 1px solid #ced4da;
  box-shadow: none;
  border-radius: 0.375rem; */
   /* border: 1px solid #ced4da; */
   box-shadow: none;
   /* border-radius: 0.375rem; */
   margin-right: 10px;  
   border: none;
   border-bottom: 1px solid #585858 !important;
   outline: none;

}
.PhoneSec{
    display: flex;
}
.RegxpErrorSec{
    width: 100%;
    text-align: center;
}

.RegxpError{
    color: #F26419;
}
.radioButton{
    vertical-align: bottom;
    margin-right: 3px;
}
.radioLabel{
    font-size: 1.2rem;
    font-weight: bolder;
}
.Return{
    width: 100%;
    text-align: center;
}
.Return span{
    color: #fa9600;
    cursor: pointer;
    text-decoration: underline;

}
.Return span:hover{
    color: #F26419;
}
@media screen and (max-width:540px) {
    .form{
        padding: 0 2rem ;
    }
    label{
        display: inline !important;
    }
    .KidProLoginMark{
        flex: 4;
    }
    .loginWayDirectMailSec{
       width: 73%;
    }
    .loginWayDirectPhoneSec{
        width: 60%;
    }

    .PhoneLable{
        display: inline-block;
    }
}
