/* section.ant-layout{
    overflow-y: scroll;
} */
/* @media screen and (max-width:767px) {
    #liff-container{
        padding: 0 1px;
        overflow-y: scroll;
    }    
} */
/* 使用者資訊區塊 */
:where(.css-dev-only-do-not-override-mxhywb).ant-row, :where(.css-dev-only-do-not-override-mxhywb).ant-col{
    width: 100%;
}