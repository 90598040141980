/* .Users .ant-layout-content {
    overflow-y: hidden!important;
} */
main.ant-layout-content#UsersRead {
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none; /* Old Firefox */
    scrollbar-width: none;  /* Firefox */
}
main.ant-layout-content#UsersAdd {
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none; /* Old Firefox */
    scrollbar-width: none;  /* Firefox */
}
.ant-list{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ant-list-item-meta-avatar{
    margin-inline-end: 0!important;
    margin: auto!important;
    /* flex: 1!important; */
    width: 15%;
    min-width: 105px;
}
.ant-list-item-meta-avatar span{
    white-space: initial;
    text-align: center;
}
.ant-list-item-meta-content{
    /* flex: 8!important; */
    width: 85%!important;;
    min-width: 338px;
}
/* 使用者列表下，分頁上 */
/* .ant-spin-nested-loading.css-dev-only-do-not-override-mxhywb {
    padding-bottom: 1.2em;
} */
@media screen and (max-width: 767px ) {
    .ant-list-item-meta{
        flex-direction: column;
        align-items: center!important;
    }
    .ant-list-item-meta-content{
        min-width: initial;
    }
    .ant-list-item-meta-avatar{
        min-width: initial;
        width: 100%;
    }
    .ant-tag{
        font-size: 18px;
        margin: 10px;
        padding: 5px 10px;
    }
    #UsersAdd .ant-tag{
        margin: 8px;
    }
}