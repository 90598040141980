.lineTemplateContent{
    display: flex;
    flex-direction: column;
}
.lineTemplateForm{
    margin: 8px auto;
    padding: 20px 40px;
    max-width: 875px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}
.lineTemplateFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.lineTemplateH4 {
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.lineTemplateH6 {
    margin: 1px 0 8px;
    flex: 2;
    min-width: 83px;
    text-align: left;
}
.lineTemplateSpaceSendBtn{
    flex: 9;
    width: 100%;
    /* min-width: 370px; */
    padding-right: 10px;
    text-align: left;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
}
.lineTemplateDivObject{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
/* .lineTemplateLabel, .lineTemplateLabel span{
    background-color: #e3e3e3;
} */
.lineTemplateDivFilterByTemplate{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.lineTemplateDivFilterByTemplate > button:nth-child(1){
    margin: 0 5px 5px 0; 
}
.lineTemplateDropdown{
    width: fit-content;
}
.lineTemplateDivAudience, .lineTemplateDivTemplate{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.lineTemplateeModalAudience, .lineTemplateeModalTemplate{
    display: flex;
    justify-content: center;
    top: 8vh;
}
.lineTemplateDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.lineTemplateSpace{
    flex: 9;
    text-align: left;
}
.lineTemplateSpaceAudience, .lineTemplateSpaceTemplate{
    flex-direction: column;
    align-items: flex-start;
}
.lineTemplateSpaceAudience{
    margin-bottom: .8rem;
}
.lineTemplateSpaceTime{
    flex: 9;
    min-width: 370px;
    text-align: left;
}
.lineTemplateInputObject{
    height: initial;
    line-height: initial;
    padding: 4px 11px 4px;
}
.lineTemplateRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.lineTemplateRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.lineTemplateRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.lineTemplateRadioSchedule{
    display: flex!important;
    align-items: center;
}
.lineTemplateRadioSchedule .ant-radio{
    align-self: start!important;
}

.lineTemplateRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.lineTemplateRadioPicker{
    display: flex;
    flex-wrap: wrap;
}
.lineTemplateRadioDatePicker, .lineTemplateRadioTimePicker{
    margin: 6px 6px 6px 0;
}
.lineTemplateFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.lineTemplateSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.lineTemplateSpaceCheckbox{
    display: flex;
}
.lineTemplateSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.lineTemplateFormItemLineCastInterface{
    width: 100%;
}
.lineTemplateSpaceLineCastInterface{
    width: 100%;
}
.lineTemplateSpaceLineCastInterface > div{
    width: 100%;
}
.lineTemplateAudienceContainer, .lineTemplateTemplateContainer, .lineTemplateAudienceDetailContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.lineTemplateAudienceDetailContainer{
    width: 100%;
    justify-content: flex-start;
}
.lineTemplateAudience, .lineTemplateSettingAudience{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.lineTemplateSettingAudience{
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
.lineTemplateSubTitle{
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.lineTemplateTags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 5px 5px 0!important;
    padding: 2px 5px;
    font-size: 14px;
}
.lineTemplateTemplateTags span[role="img"]{
    color: #1d39c4;
}
.lineTemplateTemplateTags span[role="img"]:hover{
    color: #adc6ff;
}
.lineTemplateTag{
    width: fit-content;
    min-width: initial;
    max-width: none;
    padding: 4px 8px;
    align-items: center;
    background-color: #33658A;
    color:#FFF;
}
.lineTemplateNoTags{
    align-items: center;
    background-color: #6ea2c9;
    color:#FFF;
}
.lineTemplateSettingTag{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
@media screen  and (max-width: 767px) {
    .lineTemplateForm{
        padding: 10px 20px;
    }
    .lineTemplateSpaceTime{
        min-width: 280px;
    } 
    .lineTemplateAudienceDetailContainer{
        width: 100%;
    }
}
@media screen  and (max-width: 540px) {
    .lineTemplateForm{
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .lineTemplateRadioDatePicker, .lineTemplateRadioTimePicker{
        margin: 6px 6px 6px 0;
    }
}
/* @media screen  and (max-width: 300px) {
    .lineTemplateeModalAudience{
        justify-content: center;
    }
} */