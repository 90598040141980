.wpApplyAbsenceModal{
    width: 100%;
}
.wpApplyAbsenceModalH5{
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wpApplyAbsenceModalApplyAbsence{
    display: flex;
    align-items: center;
}
.wpApplyAbsenceModalDiv{
    margin-bottom: .5rem;
    flex-wrap: wrap;
}
.wpApplyAbsenceModalMakeUp{
    width: 100%;
    display: flex;
}
.wpApplyAbsenceModalMakeUpLabel{
    /* flex: 1.4; */
    margin-right: 8px;
    font-weight: 600;
}
.wpApplyAbsenceModalMakeUpText{
    /* flex: 7.6; */
    color: #686868;
}
.wpApplyAbsenceModalErrMsg{
    width: 100%;
    margin: 4px;
    color:rgb(255,100,100);
    text-align: center;
}
/* .wpApplyAbsenceModalDropdown{
    flex: 7;
} */

