.adminMain{
    /* min-width: 1080px; */
    margin: 20px;
}
.adminContentLayout{
    /* max-width: 875px; */
    width: 100%;
    height: 100%;
    /* max-width: 1600px; */
    /* margin: 20px auto; */
    border-radius: 3px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25)
}
.adminIcon{
    font-size: 2em!important;
}
.adminChatRoomScrollTop{
    z-index: 20;
    position: sticky;
    bottom: 30%;
    float: right;
    margin-right: 8px;
    padding: 2px 7px 0!important;
    margin-top: -24px;
    opacity: 0.8;
}
/* .adminComponentLayout{
    position: relative;
} */
/* .adminFooter{
    z-index: 5;
    padding: 5px 0; 
    text-align: 'center';     
} */
/* h1{
    color: #F8F8F8;
}
h2{
    color: #F26419;
}
.adminNavbar{
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 128px;
    background-color: #1E8C96DD;
    border-radius: 5px 5px 0 0;
    box-shadow: 1px 5px 5px -1px rgba(0, 0, 0, 0.25);
}
.adminSection{
    display: flex;
    width: 100%;
    height: 540px;
    border-radius: 0 0 5px 5px;
}
.adminSidebar{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #FA960066;
    border-radius: 0 0 0 5px;
}
.adminSidebar div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border:1px solid #F2641944;
    border-width: 1px 0 0;
}
.adminSidebar div:hover{
    background-color: #F2641933;
    border:none;
    cursor:pointer;
}
.adminSidebar div:hover h2{
    color: #FEFEFE;
}
.adminSidebar div:nth-child(1){
    border-width: 0;
}
.adminContent{
    background-color: #F8F8F8;
    border-radius: 0 0 5px 0;
}
.adminContent{
    flex:5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
} */
@media screen and (max-width: 1510px) {
    .adminMain{
        margin: 0;
    }
}
@media screen and (max-width: 991px) {
    .adminMain{
        min-width: initial;
    }
}
@media screen and (max-width: 767px) {
    .adminChatRoomScrollTop{
        position: fixed;
        bottom: 30%;
        right: 1%;
    }
}