.lineAttributeLayout{
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
    border-radius: 3px;
    /* max-height: 92vh; */
}
.lineAttributeOuterContent{
    display: flex;
    /* align-items: center; */
    overflow-y: hidden!important;
}
.lineAttributeContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.lineAttributeContent h1, .lineAttributeContent h2, .lineAttributeContent h3, .lineAttributeContent h4, .lineAttributeContent h5, .lineAttributeContent h6{
    margin-bottom: .6em;
}
.lineAttributeContent div, .lineAttributeContent span, .lineAttributeContent p {
    font-size: 16px;
}
.lineAttributeSearchSec{
    width: 80%;
    max-width: 1024px;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* max-height: 78vh;
    overflow-y: scroll; */
}
.lineAttributeSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.lineAttributeSearch{
    width: 100%;
    margin: 1% 0 1.2% 2.5%;
}
.lineAttributeSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.lineAttributeSearch svg{
    display: flex;
    align-items: center;
}
.lineAttributeList{
    width: 100%;
}
.lineAttributeListItemMeta{
    flex-wrap: wrap;
}
.lineAttributeInputSpace{
    margin: 0.5em;
}
.lineAttributeDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.lineAttributeAddIconDiv{
    display: flex;
    flex-direction: row;
}
.lineAttributeAddIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.lineAttributeAddIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.lineAttributeDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.lineAttributeDescriptionItemName {
    width: 200px;
}
.lineAttributeDescriptionItemCategory{
    width: 235px;
}
.lineAttributeDescriptionItemDescription{
    width: 320px;
}
.lineAttributeDescriptionItemPriority{
    width: 125px;
}
.lineAttributeDescriptionItemUpdateBtn{
    width: 100%;
}
.lineAttributeDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.lineAttributeDescriptionLabel{
    font-weight: 800;
    color: #777;
}
.lineAttributeDescriptionLabelName, .lineAttributeDescriptionLabelCategory, .lineAttributeDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.lineAttributeDescriptionLabelPriority{
    /* flex: 4; */
    width: 70px;
}
.lineAttributeDescriptionInputName{
    /* flex: 2; */
    width: 100px;
}
.lineAttributeDescriptionInputCategory{
    /* flex: 3; */
    width: 135px;
}
.lineAttributeDescriptionInputDescription{
    /* flex: 4; */
    width: 220px;
}
.lineAttributeDescriptionInputPriority{
    /* flex: 0.5; */
    width: 55px;
}
@media screen and (max-width: 767px) {
    .lineAttributeContent div, .lineAttributeContent span, .lineAttributeContent p {
        font-size: 14px;
    }
    .lineAttributeSearchSec{
        width: 100%;
    }
    .lineAttributeSearch{
        width: 70%;
        min-width: 280px;
    }
    .lineAttributeDescriptionDiv{
        flex-direction: column;
    }
    .lineAttributeDescriptionItemDescription{
        width: 260px;
    }
    .lineAttributeDescriptionInputDescription{
        width: 160px;
    }
    .lineAttributeDescriptionItem{
        width: 100%;
        margin: 6px;
    }
    .lineAttributeDescriptionItemTitle{
        flex-direction: column;
    }
    .lineAttributeAddIcon{
        margin: 5px!important;
    }
}