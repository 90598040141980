.lineAudienceLayout{
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
    border-radius: 3px;
    /* max-height: 92vh; */
}
.lineAudienceOuterContent{
    display: flex;
    /* align-items: center; */
    overflow-y: hidden!important;
}
.lineAudienceContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.lineAudienceContent h1, .lineAudienceContent h2, .lineAudienceContent h3, .lineAudienceContent h4, .lineAudienceContent h5, .lineAudienceContent h6{
    margin-bottom: .6em;
}
.lineAudienceContent div, .lineAudienceContent span, .lineAudienceContent p {
    font-size: 16px;
}
.lineAudienceSearchSec{
    width: 80%;
    max-width: 1024px;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* max-height: 78vh;
    overflow-y: scroll; */
}
.lineAudienceSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.lineAudienceSearch{
    width: 100%;
    margin: 1% 0 1.2% 2.5%;
}
.lineAudienceSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.lineAudienceSearch svg{
    display: flex;
    align-items: center;
}
.lineAudienceList{
    width: 100%;
}
.lineAudienceListItemMeta{
    flex-wrap: wrap;
}
.lineAudienceInputSpace{
    margin: 0.5em;
}
.lineAudienceDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.lineAudienceAddIconDiv{
    display: flex;
    flex-direction: row;
}
.lineAudienceAddIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.lineAudienceAddIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.lineAudienceDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.lineAudienceDescriptionItemName {
    width: 200px;
}
.lineAudienceDescriptionItemCategory{
    width: 235px;
}
.lineAudienceDescriptionItemDescription{
    width: 320px;
}
.lineAudienceDescriptionItemPriority{
    width: 125px;
}
.lineAudienceDescriptionItemUpdateBtn{
    width: 100%;
}
.lineAudienceDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.lineAudienceDescriptionLabel{
    font-weight: 800;
    color: #777;
}
.lineAudienceDescriptionLabelName, .lineAudienceDescriptionLabelCategory, .lineAudienceDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.lineAudienceDescriptionLabelPriority{
    /* flex: 4; */
    width: 70px;
}
.lineAudienceDescriptionInputName{
    /* flex: 2; */
    width: 100px;
}
.lineAudienceDescriptionInputCategory{
    /* flex: 3; */
    width: 135px;
}
.lineAudienceDescriptionInputDescription{
    /* flex: 4; */
    width: 220px;
}
.lineAudienceDescriptionInputPriority{
    /* flex: 0.5; */
    width: 55px;
}
@media screen and (max-width: 767px) {
    .lineAudienceContent div, .lineAudienceContent span, .lineAudienceContent p {
        font-size: 14px;
    }
    .lineAudienceSearchSec{
        width: 100%;
    }
    .lineAudienceSearch{
        width: 70%;
        min-width: 280px;
    }
    .lineAudienceDescriptionDiv{
        flex-direction: column;
    }
    .lineAudienceDescriptionItemDescription{
        width: 260px;
    }
    .lineAudienceDescriptionInputDescription{
        width: 160px;
    }
    .lineAudienceDescriptionItem{
        width: 100%;
        margin: 6px;
    }
    .lineAudienceDescriptionItemTitle{
        flex-direction: column;
    }
    .lineAudienceAddIcon{
        margin: 5px!important;
    }
}