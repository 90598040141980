.wpSetTeachingPlanForm{
    /* margin: 8px auto; */
    /* padding: 20px 40px; */
    /* max-width: 505px; */
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* min-height: 80vh; */
}
.wpSetTeachingPlanContent{
    /* margin: 8px auto!important; */
    /* max-width: 505px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 292px; */
}
.wpSetTeachingPlanContent>section{
    margin: 13px auto 8px;
    max-width: 415px;
    width: 94%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.wpSetTeachingPlanOuterSection{
    width: 100%;
    max-width: 1024px!important;
    display: flex;
    flex-direction: row!important;
    flex-wrap: wrap;
    justify-content: center;
}
.wpSetTeachingPlanOuterSectionAddClass{
    margin: 0 auto!important;
}
.wpSetTeachingPlanSection{
    width: 100%;
    /* padding: 0 40px; */
}
.wpSetTeachingPlanBtnSection{
    flex-direction: row!important;
    max-width: fit-content!important;
}
.wpSetTeachingPlanSectionTitle{
    padding-top: 15px;
}
.wpSetTeachingPlanSectionCourseIdName .wpSetTeachingPlanFormItem{
    margin-bottom: 1rem!important;
}
.wpSetTeachingPlanSectionCourseIdName .wpSetTeachingPlanDivNameAndAddBtn  .wpSetTeachingPlanFormItem{
    margin-bottom: 0!important;
}
.wpSetTeachingPlanSectionAddAbility, .wpSetTeachingPlanSectionAddItem, .wpSetTeachingPlanSectionAddActivity{
    max-width: 460px;
    padding: 10px 20px;
}
.wpSetTeachingPlanDivAbility, .wpSetTeachingPlanDivItem, .wpSetTeachingPlanDivActivity{
    padding: 15px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    background-color: #FAFAFA;
}
/* .wpSetTeachingPlanSection:last-child{
    padding-bottom: 20px;
} */
.wpSetTeachingPlanFormItem{
    width: 100%;
    max-width: 540px;
    /* margin: auto!important; */
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpSetTeachingPlanDivAbility .wpSetTeachingPlanFormItem, .wpSetTeachingPlanDivItem .wpSetTeachingPlanFormItem, .wpSetTeachingPlanDivActivity .wpSetTeachingPlanFormItem{
    margin-bottom: 0.6rem!important;
}
.wpSetTeachingPlanFormItemCompensate, .wpSetTeachingPlanFormItemName, .wpSetTeachingPlanFormItemAddClass{
    margin: 0!important;
}
.wpSetTeachingPlanFormItemCompensate, .wpSetTeachingPlanFormItemName{
    /* margin-right: 8px!important; */
    flex: 5;
    display: flex;
    align-items: center;
}
.wpSetTeachingPlanFormItemAddClass{
    flex: 3;
}
.wpSetTeachingPlanFormItemSendBtn{
    margin: auto!important;
    width: initial;
}
.wpSetTeachingPlanH4 {
    font-weight: 600;
    /* margin-bottom: .6rem; */
    margin-bottom: 0;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpSetTeachingPlanH6 {
    /* margin: 1px 0 8px; */
    margin: 0 8px 0 0;
    flex: 2;
    /* min-width: 95px; */
    text-align: left;
}
.wpSetTeachingPlanDivNameAndAddBtn{
    width: 100%;
    /* margin-bottom: 1rem; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpSetTeachingPlanSpaceSendBtn{
    flex: 9;
    width: 100%;
    margin-top: .5rem;
    /* margin-bottom: 0.3rem; */
    /* min-width: 370px; */
    /* padding-right: 10px; */
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.wpSetTeachingPlanSpaceUpdateBtn{
    margin: 8px;
}
.wpSetTeachingPlanSpaceUpdateBtnText div{
    font-size: 14px!important;
}
/* .wpSetTeachingPlanSpaceSendBtn button{
    height: initial;
}
.wpSetTeachingPlanSpaceSendBtn button div{
    font-size: initial;
} */
.wpSetTeachingPlanSpaceSendBtnAdd{
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;
}
/* .wpSetTeachingPlanSpaceSendBtnAdd button div{
    font-size: 14px;
    padding: 2px 1px;
} */
.wpSetTeachingPlanOuterDivInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetTeachingPlanSpaceInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetTeachingPlanSpaceInputFormal{
    flex-wrap: nowrap;
}
.wpSetTeachingPlanInputFormal{
    margin-right: 8px;
}
.wpSetTeachingPlanDivDeleteOutlined{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.wpSetTeachingPlanDelIcon{
    cursor: pointer;
    margin: 2px 1px 1px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpSetTeachingPlanDelIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
/* .wpSetTeachingPlanSpaceInputName{
    flex-wrap: wrap;
    align-items: initial;
} */
/* .wpSetTeachingPlanDivInput{
    display: flex;
    flex-wrap: wrap;
} */
.wpSetTeachingPlanInput{
    width: 324px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpSetTeachingPlanInputCategory{
    margin-top: 8px;
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetTeachingPlanInputName{
    width: 340px;
    min-width: 250px;
    padding: 4px 15px;
}
.wpSetTeachingPlanInputKidCount{
    width: 80px;
    min-width: 50px;
    padding: 4px 15px;
    border-radius: 5px;
}
.wpSetTeachingPlanInputPeriod{
    width: 80px;
    min-width: 80px;
    padding: 4px 0;
    border-radius: 5px;
}
.wpSetTeachingPlanInputItem{
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
    border-radius: 5px;
}
.wpSetTeachingPlanInputIntro{
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetTeachingPlanInputIntro.wpSetTeachingPlanInputIntroUpdate{
    width: 100%;
}
.wpSetTeachingPlanInputPrice{
    width: 120px;
    min-width: 120px;
    padding: 4px 15px;
}
.wpSetTeachingPlanInputPriceUpdate{
    max-width: 180px;
}
.wpSetTeachingPlanInputPlace{
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpSetTeachingPlanInputMaximum{
    width: 80px;
    min-width: 80px;
    padding: 4px 15px;
}
.wpSetTeachingPlanDropdownCategory{
    width: 324px;
    min-width: 209px;
    padding: 4px 15px;
}
.wpSetTeachingPlanDropdownBtnSpace{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
/* .wpSetTeachingPlanSpaceDropdown{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} */
/* .wpSetTeachingPlanSpaceDropdown h6{
    margin-bottom: 1px!important;
} */
.wpSetTeachingPlanDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpSetTeachingPlanFlex1{
    flex: 2;
    text-align: left;
}
.wpSetTeachingPlanFlex2{
    flex: 9;
    text-align: left;
}
.wpSetTeachingPlanSpaceTime{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetTeachingPlanRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpSetTeachingPlanRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpSetTeachingPlanRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpSetTeachingPlanRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpSetTeachingPlanRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpSetTeachingPlanDivTimePicker{
    display: flex;
    flex-wrap: wrap;
}
.wpSetTeachingPlanDatePicker{
    /* margin-right: 8px; */
    width: 150px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpSetTeachingPlanTimePicker{
    /* margin-right: 8px; */
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpSetTeachingPlanFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpSetTeachingPlanSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpSetTeachingPlanSpaceCheckbox{
    display: flex;
}
.wpSetTeachingPlanSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpSetTeachingPlanFormItemLineCastInterface{
    width: 100%;
}
.wpSetTeachingPlanSpaceLineCastInterface{
    width: 100%;
}
.wpSetTeachingPlanSpaceLineCastInterface > div{
    width: 100%;
}
.wpSetTeachingPlanFilterConsultContent{
    margin: 0.8rem 0 2em!important;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
/* .wpSetTeachingPlanFilterConsultContent h1, .wpSetTeachingPlanFilterConsultContent h2, .wpSetTeachingPlanFilterConsultContent h3, .wpSetTeachingPlanFilterConsultContent h4, .wpSetTeachingPlanFilterConsultContent h5, .wpSetTeachingPlanFilterConsultContent h6{
    margin-bottom: .6em;
} */
.wpSetTeachingPlanFilterConsultContent div, .wpSetTeachingPlanFilterConsultContent span, .wpSetTeachingPlanFilterConsultContent p {
    font-size: 16px;
}
.wpSetTeachingPlanFilterConsultContentQuery h5{
    /* color: #1677ff; */
    font-weight: 800;
}
.wpSetTeachingPlanFilterConsultSearchSec{
    width: 80%;
    max-width: 875px;
    min-width: 875px;
    height: 50vh;
    margin: 10px auto;
    padding: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 3px -1px 10px -1px #00152933;
    border-radius: 3px;
    background-color: #F9F9F9;
}
.wpSetTeachingPlanFilterConsultTitle h4{
    font-weight: 800;
    color: #001529;
}
.wpSetTeachingPlanFilterConsultList{
    width: 100%;
    /* border: 1px solid #1677ff; */
    border-radius: 5px;
    padding: 10px;
}
.wpSetTeachingPlanFilterConsultList ul{
    max-height: 47vh;
    overflow-y: scroll;
    padding: 0 12px!important;
}
/* .wpSetTeachingPlanFilterConsultList span{
    color: #1677ff;
} */
.wpSetTeachingPlanFilterConsultListItemMeta{
    flex-wrap: wrap;
    flex-direction: column;
    /* cursor: pointer; */
}
/* .wpSetTeachingPlanFilterConsultListItemMeta:hover{
    background-color: #dedede;
} */
.wpSetTeachingPlanFilterConsultCourseDescriptionDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionPanel .wpSetTeachingPlanFilterConsultCourseDescriptionQueryDiv{
    border-bottom: 1px solid #ddd;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionPanel .wpSetTeachingPlanFilterConsultCourseDescriptionQueryDiv:last-child{
    border-bottom: initial;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItem{
    /* width: 100%; */
    margin: 8px;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: flex-start;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemName {
    width: 240px;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemCategory{
    width: 300px;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemDescription{
    width: 360px;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemPriority{
    width: 145px;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionText{
    width: 240px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionLabel{
    font-weight: 800;
    color: #001529cc;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionName{
    margin-right: 8px;
    font-weight: 800;
    text-align: left;
    white-space: nowrap;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionLabelName, .wpSetTeachingPlanFilterConsultCourseDescriptionLabelCategory, .wpSetTeachingPlanFilterConsultCourseDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.wpSetTeachingPlanFilterConsultDiv{
    display: flex;
    flex-direction: row;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDiv{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.wpSetTeachingPlanFilterConsultIconDiv{
    margin: 8px 4px;
    display: flex;
    justify-content: flex-end;
}
.wpSetTeachingPlanFilterConsultIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpSetTeachingPlanFilterConsultIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDiv{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDivUpdate{
    flex-direction: column;
    align-items: flex-start;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitle{
    max-width: 315px;
    min-width: 250px;
    /* max-width: 70%; */
}

.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleIntro{
    max-width: initial;
    width: 100%;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDivUpdate{
    align-items: flex-start;
    flex-direction: row;
}
.wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDivUpdate{
    flex-direction: column;
}
.wpSetTeachingPlanDivInputName, .wpSetTeachingPlanDivInputCategory{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1510px) {
    .wpSetTeachingPlanFilterConsultSearchSec{
        height: 57vh;
    }
}
@media screen and (max-width: 1220px) {
    .wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDiv{
        align-items: flex-start;
    }
    .wpSetTeachingPlanFilterConsultCourseDescriptionText{
        width: initial;
    }
}
@media screen and (max-width: 1106px) {
    .wpSetTeachingPlanOuterSection{
        justify-content: center;
    }
}
@media screen and (max-width: 1090px) {
    .wpSetTeachingPlanFilterConsultSearchSec{
        min-width: initial;
    }
}
@media screen  and (max-width: 900px) {
    .wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDiv{
        max-width: 335px;
    }
    .wpSetTeachingPlanFilterConsultCourseDescriptionItemTitle{
        max-width: 95%;
    }
}
@media screen  and (max-width: 767px) {
    .wpSetTeachingPlanContent>section{
        margin: 8px 0;
    }
    .wpSetTeachingPlanH4 {
        margin-top: .3rem;
    }
    .wpSetTeachingPlanFilterConsultIcon{
        margin: 2px 5px 2px 1px!important;
    }
    .wpSetTeachingPlanForm{
        /* padding: 10px 20px; */
        padding: 5px; 
    }
    .wpSetTeachingPlanFilterConsultContent div, .wpSetTeachingPlanFilterConsultContent span, .wpSetTeachingPlanFilterConsultContent p {
        font-size: 14px;
    }
    /* .wpSetTeachingPlanFilterConsultContentQuery{
        min-width: 292px;
    } */
    .wpSetTeachingPlanFilterConsultContentQuery h5{
        font-size: 18px;
    }
    .wpSetTeachingPlanFilterConsultSearchSec{
        width: 100%;
        /* min-width: 292px; */
    }
    .wpSetTeachingPlanFilterConsultList{
        padding: 0px 8px;
    }
    .wpSetTeachingPlanFilterConsultCourseDescriptionDiv{
        width: 100%;
        /* flex-direction: column; */
    }
    .wpSetTeachingPlanFilterConsultCourseDescriptionItem {
        margin: 8px 4px;
        /* width: 100%; */
        /* justify-content: flex-start; */
    }
    .wpSetTeachingPlanFilterConsultCourseDescriptionItemUpdateBtn{
        justify-content: center;
    }
    .wpSetTeachingPlanContent{
        max-width: initial;
    }
    .wpSetTeachingPlanSection{
        /* min-width: 300px; */
        padding: 0;
    }
    /* .wpSetTeachingPlanSectionCourseIdName{
        padding: 0 20px 0;
    } */
    .wpSetTeachingPlanSectionAddActivity{
        padding: 0.6rem 0 0;
    }
    /* .wpSetTeachingPlanDivActivity{
        min-width: 292px;
    } */
    .wpSetTeachingPlanDelIcon{
        margin: 5px!important;
    }
    .wpSetTeachingPlanInputPlace{
        width: 100%;
        min-width: 252px;
    }
    .wpSetTeachingPlanTimePicker{
        width: 100%;
        min-width: 252px;
    }
    .wpSetTeachingPlanDropdownCategory{
        /* width: 100%; */
        min-width: 209px;
    }
    .wpSetTeachingPlanInputKidCount{
        width: 80px!important;
        min-width: 80px;
    }
    .wpSetTeachingPlanInputPeriod{
        width: 80px!important;
        min-width: 80px;
    }
    .wpSetTeachingPlanFilterConsultCourseDescriptionItemTitleDiv{
        flex-direction: column-reverse;
    }
    /* .wpSetTeachingPlanSpaceTime{
        min-width: 280px;
    } */
    .wpSetTeachingPlanFilterConsultSearchSec{
        height: 100%;
        padding: 18px 12px;
    }
    .wpSetTeachingPlanSpaceInputPeriod{
        width: 85%;
    }
}
@media screen  and (max-width: 540px) {
    /* .wpSetTeachingPlanForm{
        padding: 5px;
    } */
    .wpSetTeachingPlanInput{
        width: 100%;
    }
    .wpSetTeachingPlanInputName{
        width: 100%;
    }
    .wpSetTeachingPlanDropdownCategory{
        width: 100%;
        /* min-width: 150px; */
    }
    .wpSetTeachingPlanFilterConsultCourseDescriptionText{
        max-width: 125px;
    }
}
@media screen  and (max-width: 431px) {
    /* .wpSetTeachingPlanDatePicker{
        margin-bottom: 8px;
    } */
}
