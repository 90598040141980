.wpOnlineCourseContent{
    display: flex;
    flex-direction: column;
}
.wpOnlineCourseForm{
    margin: 8px auto;
    padding: 15px 40px;
    max-width: 875px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}
.wpOnlineCourseFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpOnlineCourseH4 {
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpOnlineCourseH6 {
    margin: 1px 0 8px;
    flex: 2;
    min-width: 83px;
    text-align: left;
}
.wpOnlineCourseSpaceSendBtn{
    flex: 9;
    width: 100%;
    /* min-width: 370px; */
    padding-right: 10px;
    text-align: left;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
}
.wpOnlineCourseDivObject{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
/* .wpOnlineCourseLabel, .wpOnlineCourseLabel span{
    background-color: #e3e3e3;
} */
.wpOnlineCourseDivFilterByAttribute{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpOnlineCourseDivFilterByAttribute > button:nth-child(1){
    margin: 0 5px 5px 0; 
}
.wpOnlineCourseDropdown{
    width: fit-content;
}
.wpOnlineCourseDivAudience, .wpOnlineCourseDivAttribute{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpOnlineCourseeModalAudience, .wpOnlineCourseeModalAttribute{
    display: flex;
    justify-content: center;
    top: 8vh;
}
.wpOnlineCourseDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpOnlineCourseSpace{
    flex: 9;
    text-align: left;
}
.wpOnlineCourseSpaceAudience, .wpOnlineCourseSpaceAttribute{
    flex-direction: column;
    align-items: flex-start;
}
.wpOnlineCourseSpaceAudience{
    margin-bottom: .8rem;
}
.wpOnlineCourseSpaceTime{
    flex: 9;
    min-width: 370px;
    text-align: left;
}
.wpOnlineCourseInputObject{
    height: initial;
    line-height: initial;
    padding: 4px 11px 4px;
}
.wpOnlineCourseRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpOnlineCourseRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpOnlineCourseRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpOnlineCourseRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpOnlineCourseRadioSchedule .ant-radio{
    align-self: start!important;
}

.wpOnlineCourseRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpOnlineCourseRadioPicker{
    display: flex;
    flex-wrap: wrap;
}
.wpOnlineCourseRadioDatePicker, .wpOnlineCourseRadioTimePicker{
    margin: 6px 6px 6px 0;
}
.wpOnlineCourseFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpOnlineCourseSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpOnlineCourseSpaceCheckbox{
    display: flex;
}
.wpOnlineCourseSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpOnlineCourseFormItemLineCastInterface{
    width: 100%;
}
.wpOnlineCourseSpaceLineCastInterface{
    width: 100%;
}
.wpOnlineCourseSpaceLineCastInterface > div{
    width: 100%;
}
.wpOnlineCourseAudienceContainer, .wpOnlineCourseAttributeContainer, .wpOnlineCourseAudienceDetailContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpOnlineCourseAudienceDetailContainer{
    width: 100%;
    justify-content: flex-start;
}
.wpOnlineCourseAudience, .wpOnlineCourseSettingAudience{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.wpOnlineCourseSettingAudience{
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
.wpOnlineCourseSubTitle{
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.wpOnlineCourseTags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 5px 5px 0!important;
    padding: 2px 5px;
    font-size: 14px;
}
.wpOnlineCourseAudienceTags span[role="img"]{
    color: #08979c;
}
.wpOnlineCourseAudienceTags span[role="img"]:hover{
    color: #87e8de;
}
.wpOnlineCourseAttributeTags span[role="img"]{
    color: #389e0d;
}
.wpOnlineCourseAttributeTags span[role="img"]:hover{
    color: #b7eb8f;
}
.wpOnlineCourseTag{
    width: fit-content;
    min-width: initial;
    max-width: none;
    padding: 4px 8px;
    align-items: center;
    background-color: #33658A;
    color:#FFF;
}
.wpOnlineCourseNoTags{
    align-items: center;
    background-color: #6ea2c9;
    color:#FFF;
}
.wpOnlineCourseSettingTag{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
@media screen  and (max-width: 767px) {
    .wpOnlineCourseForm{
        padding: 10px 20px;
    }
    .wpOnlineCourseSpaceTime{
        min-width: 280px;
    } 
    .wpOnlineCourseAudienceDetailContainer{
        width: 100%;
    }
}
@media screen  and (max-width: 540px) {
    .wpOnlineCourseForm{
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .wpOnlineCourseRadioDatePicker, .wpOnlineCourseRadioTimePicker{
        margin: 6px 6px 6px 0;
    }
}
/* @media screen  and (max-width: 300px) {
    .wpOnlineCourseeModalAudience{
        justify-content: center;
    }
} */