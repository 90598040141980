.tagsReadLayout{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
}
.tagsReadOuterContent{
    display: flex;
    /* align-items: center; */
    overflow-y: hidden!important;
}
.tagsReadContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.tagsReadContent h1, .tagsReadContent h2, .tagsReadContent h3, .tagsReadContent h4, .tagsReadContent h5, .tagsReadContent h6{
    margin-bottom: .6em;
}
.tagsReadContent div, .tagsReadContent span, .tagsReadContent p {
    font-size: 16px;
}
.tagsReadSearchSec{
    width: 80%;
    max-width: 1024px;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: column;
}
.tagsReadSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.tagsReadSearch{
    width: 100%;
    margin: 1% 0 1.2% 2.5%;
}
.tagsReadSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.tagsReadSearch svg{
    display: flex;
    align-items: center;
}
.tagsReadList{
    width: 100%;
}
.tagsReadListItemMeta{
    flex-wrap: wrap;
}
.tagsReadInputSpace{
    margin: 0.5em;
}
.tagsReadTagDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.tagsReadTagEditAndDelIconDiv{
    display: flex;
    flex-direction: row;
}
.tagsReadTagEditAndDelIcon{
    cursor: pointer;
    margin: 2px 1px 1px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.tagsReadTagEditAndDelIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.tagsReadTagDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.tagsReadTagDescriptionItemName {
    width: 200px;
}
.tagsReadTagDescriptionItemCategory{
    width: 235px;
}
.tagsReadTagDescriptionItemDescription{
    width: 320px;
}
.tagsReadTagDescriptionItemPriority{
    width: 125px;
}
.tagsReadTagDescriptionItemUpdateBtn{
    width: 100%;
}
.tagsReadTagDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.tagsReadTagDescriptionLabel{
    font-weight: 800;
    color: #777;
}
.tagsReadTagDescriptionLabelName, .tagsReadTagDescriptionLabelCategory, .tagsReadTagDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.tagsReadTagDescriptionLabelPriority{
    /* flex: 4; */
    width: 70px;
}
.tagsReadTagDescriptionInputName{
    /* flex: 2; */
    width: 100px;
}
.tagsReadTagDescriptionInputCategory{
    /* flex: 3; */
    width: 135px;
}
.tagsReadTagDescriptionInputDescription{
    /* flex: 4; */
    width: 220px;
}
.tagsReadTagDescriptionInputPriority{
    /* flex: 0.5; */
    width: 55px;
}
@media screen and (max-width: 767px) {
    .tagsReadSearchSec{
        width: 100%;
    }
    .tagsReadSearch{
        width: 70%;
        min-width: 280px;
    }
    .tagsReadTagDescriptionDiv{
        flex-direction: column;
    }
    .tagsReadTagDescriptionItemDescription{
        width: 260px;
    }
    .tagsReadTagDescriptionInputDescription{
        width: 160px;
    }
    .tagsReadTagDescriptionItem{
        width: 100%;
    }
    .tagsReadTagDescriptionItemTitle{
        flex-direction: column;
    }
    .tagsReadTagEditAndDelIcon{
        margin: 5px!important;
    }
}