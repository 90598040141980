.wordpressPCMenu{
    display: flex;
}
.wordpressPhoneMenu{
    display: none;
}
@media screen and (max-width: 767px) {
    .wordpressPCMenu{
        display: none;
    }
    .wordpressPhoneMenu{
        display: flex;
        flex-direction: column;
    }
    /* .wordpressPopupMenu{
        top: 0;
        bottom: 0;
    }  */
}
