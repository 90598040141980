#WordpressContent{
    height: 86vh;
    overflow-y: scroll;
}
#Wordpress label.ant-checkbox-wrapper.css-dev-only-do-not-override-mxhywb{
    display: flex!important;
}
/* primary按鈕樣式 */
/* #Wordpress .ant-btn-primary{
    height: initial;
} */
#Wordpress #WordpressPhoneMenu :where(.css-dev-only-do-not-override-mxhywb).ant-btn-primary:not(:disabled):active, 
#Wordpress #WordpressPhoneMenu :where(.css-dev-only-do-not-override-mxhywb).ant-btn-primary:not(:disabled):hover{
    background-color: #1677ff;
}
/* 菜單文字 */
#Wordpress .ant-menu-title-content{
    text-align: left;
}
/* 子菜單背景 */
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    background-color: #142637;
}
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    background-color: #213243;
}
/* 子菜單邊界 */
/* li.ant-menu-submenu.ant-menu-submenu-inline {
    border: 1px solid #F5F5F5;
    border-radius: 0;
    border-width: 1px 0;
} */
/* 新增課程按鈕 */
#Wordpress .ant-btn-primary, #Wordpress .ant-btn-default{
    display: flex;
    align-items: center;
}
#Wordpress #addCourse .ant-btn-primary, #Wordpress #addAbilities .ant-btn-primary, 
#Wordpress #addItems .ant-btn-primary, #Wordpress #addActivities .ant-btn-primary, #Wordpress #setAdviceReport .ant-btn-primary{
    margin: 8px 8px 8px 0;
    /* padding: 12px 6px 12px 8px; */
}
/* 儲存草稿按鈕V */
#Wordpress .ant-dropdown-trigger{
    display: flex;
    align-items: center;
    justify-content: center;
}
#Wordpress #WpApplyAbsenceModalContainer .ant-dropdown-trigger{
    width: 83%;
    max-width: 395px;
    padding: 4px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
#Wordpress #WpApplyAbsenceModalContainer .ant-dropdown-trigger > div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#Wordpress .anticon-down svg{
    font-size: 8px!important;
    line-height: 8px;
}
/* input label */
/* #Wordpress .ant-input-group-addon{
    background-color: #e3e3e3;
} */


/* #Wordpress #Narrowcast.ant-layout-content {
    width: 100%;
    overflow-y: scroll;
    max-height: 86vh;
} */
/* #Wordpress .ant-layout-content {
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    overflow: -moz-scrollbars-none; 
    scrollbar-width: none;  
} */
#Wordpress #wpReadParentChildCourseSpaceDropdown .ant-space-item{
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
#Wordpress #wpApplyAbsenceModalDropdown .ant-space-item:nth-child(1){
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
#Wordpress #wpApplyAbsenceModalDropdown .ant-space-item:nth-child(2){
    display: flex;
    text-align: left;
}
#Wordpress #abilityMonthRange .ant-space-item:nth-child(2){
    margin-top: 20px;
}
#Wordpress .ant-list{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
#Wordpress .ant-list-item-meta-avatar{
    margin-inline-end: 0!important;
    margin: auto!important;
    /* flex: 1!important; */
    width: 15%;
    min-width: 72px;
}
#Wordpress .ant-list-item-meta-avatar span{
    white-space: initial;
    text-align: center;
}
#Wordpress .ant-list-item-meta-content{
    /* flex: 8!important; */
    width: 100%!important;;
    min-width: 300px;
}
#Wordpress button.ant-modal-close{
    top: 10px;
}
/* 標籤列表下，分頁上 */
/* #Wordpress .ant-spin-nested-loading.css-dev-only-do-not-override-mxhywb {
    padding-bottom: 1.2em;
} */
#Wordpress .ant-modal-content{
    padding: 1.5rem!important;
    background-color: #f5f5f5!important;
    width: 100%!important;
}
#Wordpress .ant-list{
    padding: .2rem;
    /* max-height: 72vh; */
    overflow-y: scroll;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;  
}
#Wordpress .ant-list::-webkit-scrollbar{
    display: none;
}
/* 表格多餘欄位 */
/* #Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper td.ant-table-cell.ant-table-row-expand-icon-cell .ant-table-thead th.ant-table-column-has-sorters:hover{
    background: #f0f0f0;
} */
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper table{
    border-collapse: collapse;
    border-bottom: 1px solid #F0F0F0;
}
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-modal .ant-modal-footer{
    display: flex;
    justify-content: flex-end;
}
/* Collapse元件 */
#Wordpress .ant-collapse{
    width: 100%;
    text-align: left;
}
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-collapse>.ant-collapse-item >.ant-collapse-header{
    align-items: center;
    font-weight: 800;
}
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 4px 16px;
}
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-list .ant-list-item .ant-list-item-action{
    margin-inline-start: 0;
    min-width: 126px;
    /* max-width: 126px; */
    overflow: hidden;
}
#Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-list .ant-list-item .ant-list-item-action>li{
    padding: 0;
}
@media screen and (max-width: 1510px) {
    #Wordpress #Narrowcast.ant-layout-content {
        max-height: initial;
        overflow-y: visible;
    }
    #WordpressContent{
        height: 95vh!important;
    }
}
@media screen and (max-width: 767px ) {
    #Wordpress button.ant-modal-close{
        top: 6px;
    }
    #Wordpress .ant-list-item-meta{
        flex-direction: column;
        align-items: center!important;
    }
    #Wordpress .ant-list-item-meta-content{
        min-width: initial;
    }
    #Wordpress .ant-list-item-meta-avatar{
        min-width: initial;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    #Wordpress .ant-tag{
        font-size: 14px;
        padding: 2px 5px;
    }
    #Wordpress .ant-tag{
        margin: 8px;
    }
}
@media screen and (max-width: 767px){
    /* Load More按鈕 */
    #Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-list .ant-list-item .ant-list-item-action{
        margin-inline-start: 0;
    }
    #Wordpress #abilityMonthRange .ant-space-item:nth-child(2){
        width: 80%;
        margin-left: 10px;
    }
}
@media screen and (max-width: 588px ) {
    #Wordpress :where(.css-dev-only-do-not-override-mxhywb).ant-radio-wrapper .ant-radio{
        align-self: flex-start;
        margin-top: 4px;
    }
    #Wordpress #WpApplyAbsenceModalContainer .ant-dropdown-trigger{
        width: 100%;
        max-width: initial;
    }
    #Wordpress #WpApplyAbsenceModalContainer :where(.css-dev-only-do-not-override-mxhywb).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item, :where(.css-dev-only-do-not-override-mxhywb).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item, :where(.css-dev-only-do-not-override-mxhywb).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title, :where(.css-dev-only-do-not-override-mxhywb).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title{
        padding: 5px 8px;
    }
}
