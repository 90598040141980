.customerChatRoomSendDiv{
    width: 98%;
    margin: auto;
    display: flex;
}
.customerChatRoomSendBtn{
    height: 50px;
    border-radius: 3px;
}
.customerChatRoomSendBtn textarea{
    height: 50px!important;
    overflow: scroll!important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none; /* Old Firefox */
    scrollbar-width: none;  /* Firefox */
}
.customerChatRoomSendBtn textarea::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}