.socketAdminSendMsgForm{
    width: 100%;
}
.socketAdminInput{
    height: 50px;
    border-radius: 1px;
}
.socketAdminBtn textarea{
    height: 50px!important;
    overflow: scroll!important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none; /* Old Firefox */
    scrollbar-width: none;  /* Firefox */
}
.socketAdminBtn textarea::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}