.customerChatRoomContentSec{
    width: 100%;
    min-height: 400px;
    padding: 10px;
}
.customerChatRoomSendSec{
    position: -webkit-sticky;
    position: sticky;
    bottom: -1px;
    z-index: 5;
    width: 100%;
    padding: 16px 12px 17px;
    background-color: #1c3852;
    box-shadow: 2px -5px 10px -5px #33333366;
}

@media screen and (max-width: 768px) {
    .customerChatRoomSendSec{
        padding: 12px 12px 13px;
        bottom: -1px;
        border-radius: 0 0 3px 3px;
    }
}