.lineBroadcastForm{
    margin: 8px auto;
    padding: 20px 40px;
    max-width: 875px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}
.lineBroadcastFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.lineBroadcastH4 {
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.lineBroadcastH6 {
    margin: 1px 0 8px;
    flex: 2;
    min-width: 83px;
    text-align: left;
}
.lineBroadcastSpaceSendBtn{
    flex: 9;
    width: 100%;
    /* min-width: 370px; */
    padding-right: 10px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.lineBroadcastDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.lineBroadcastSpaceTime{
    flex: 9;
    min-width: 370px;
    text-align: left;
}
.lineBroadcastSpaceTime{
    flex: 9;
    min-width: 370px;
    text-align: left;
}
.lineBroadcastRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.lineBroadcastRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.lineBroadcastRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.lineBroadcastRadioSchedule{
    display: flex!important;
    align-items: center;
}
.lineBroadcastRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.lineBroadcastRadioPicker{
    display: flex;
    flex-wrap: wrap;
}
.lineBroadcastRadioDatePicker{
    margin-right: 8px;
}
.lineBroadcastRadioTimePicker{
    margin-right: 8px;
}
.lineBroadcastFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.lineBroadcastSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.lineBroadcastSpaceCheckbox{
    display: flex;
}
.lineBroadcastSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.lineBroadcastFormItemLineCastInterface{
    width: 100%;
}
.lineBroadcastSpaceLineCastInterface{
    width: 100%;
}
.lineBroadcastSpaceLineCastInterface > div{
    width: 100%;
}
@media screen  and (max-width: 767px) {
    .lineBroadcastForm{
        padding: 10px 20px;
    }
    .lineBroadcastSpaceTime{
        min-width: 280px;
    }
}
@media screen  and (max-width: 540px) {
    .lineBroadcastForm{
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .lineBroadcastRadioDatePicker{
        margin-bottom: 8px;
    }
}
