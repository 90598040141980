/* Form 下方的DIV */
#Customer .ant-form-item.css-dev-only-do-not-override-mxhywb {
    margin: 0;
}
/* 上傳功能區塊 */
#Customer .ant-upload-wrapper{
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 10px; */
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    /* margin-right: 87px; */
    margin-right: 111px;
    position: relative;
    top: 85px;
    right: -7px;
}
/* Upload按鈕 */
#Customer :where(.css-dev-only-do-not-override-mxhywb).ant-upload-wrapper .ant-upload-select {
    display: flex;
    /* flex: 1; */
    justify-content: flex-end;

    z-index: 2;
    position: relative;
    top: -32px;
    /* top: -14px; */
}
/* 預覽圖片列表水平並列 */
#Customer .ant-upload-list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    z-index: 10;
}
/* 預覽圖片寬度 */
#Customer .ant-upload-list-picture {
    flex: 8;
    margin-right: 7px;
    margin-left: -2px;
    position: relative;
    width: 100%;
    top: -10px;
    background-color: #FFF;
}
/* 上傳檔案預覽置中 */
#Customer .ant-upload-list-item-thumbnail{
    display: flex;
    align-items: center;
    justify-content: center;
}
#Customer .ant-upload-list-item-container{
    width: 100%;
    padding-left: 4px;
    max-width: 367px;
}/* END 預覽圖片寬度 */
#Customer .ant-upload-list-item-name{
    color: #FFF
}
#Customer .ant-upload-list-item-container div:nth-child(1){
    margin-top: 0px!important;
}
/* 文件上傳中的預覽文字顏色 */
#Customer .ant-upload-list-item-name{
    color: #666;
}
/* UserDetail中刪除icon */
#Customer span.anticon.anticon-delete {
    margin-left: 2px;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 1275px) {
    #Customer .ant-upload-list-item-container{
        max-width: 260px;
    }
}
@media screen and (max-width: 1013px) {
    #Customer :where(.css-dev-only-do-not-override-mxhywb).ant-upload-wrapper .ant-upload-select {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    #Customer .ant-upload-list-picture {
        width: 100%!important;
        display: flex;
        /* justify-content: flex-end; */
        margin-right: initial;
    }
    #Customer .ant-upload-list-item-container div:nth-child(1){
        margin-top: 8px!important;
    }
}
@media screen and (max-width: 767px) {
    /* 上傳功能區塊 */
    #Customer .ant-upload-wrapper{
        margin-right: 103px;
    }
}
@media screen and (max-width: 367px) {
    /* 上傳功能區塊 */
    #Customer .ant-upload-wrapper{
        margin-right: 38%;
    }
}