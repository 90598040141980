#LINE label.ant-checkbox-wrapper.css-dev-only-do-not-override-mxhywb{
    display: flex!important;
}
/* 儲存草稿按鈕V */
#LINE .ant-dropdown-trigger{
    display: flex;
    align-items: center;
    justify-content: center;
}
#LINE .anticon-down{
    font-size: 12px;
    line-height: 12px;

}
/* input label */
#LINE .ant-input-group-addon{
    background-color: #e3e3e3;
}


#LINE #Narrowcast.ant-layout-content {
    width: 100%;
    overflow-y: scroll;
    max-height: 86vh;
}
/* #LINE .ant-layout-content {
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    overflow: -moz-scrollbars-none; 
    scrollbar-width: none;  
} */
/* #LINE .ant-space-item{
    width: fit-content;
} */
#LINE .ant-list{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#LINE .ant-list-item-meta-avatar{
    margin-inline-end: 0!important;
    margin: auto!important;
    /* flex: 1!important; */
    width: 15%;
    min-width: 72px;
}
#LINE .ant-list-item-meta-avatar span{
    white-space: initial;
    text-align: center;
}
#LINE .ant-list-item-meta-content{
    /* flex: 8!important; */
    width: 85%!important;;
    min-width: 300px;
}
#LINE button.ant-modal-close{
    top: 10px;
}
/* 標籤列表下，分頁上 */
/* #LINE .ant-spin-nested-loading.css-dev-only-do-not-override-mxhywb {
    padding-bottom: 1.2em;
} */
#LINE .ant-modal-content{
    padding: 1.5rem!important;
    background-color: #f5f5f5!important;
}
#LINE .ant-list{
    padding: .2rem;
    max-height: 72vh;
    overflow-y: scroll;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;  
}
#LINE .ant-list::-webkit-scrollbar{
    display: none;
}
/* 表格多餘欄位 */
/* #LINE :where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper td.ant-table-cell.ant-table-row-expand-icon-cell .ant-table-thead th.ant-table-column-has-sorters:hover{
    background: #f0f0f0;
} */
#LINE :where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper table{
    border-collapse: collapse;
    border-bottom: 1px solid #F0F0F0;
}
@media screen and (max-width: 1510px) {
    #LINE #Narrowcast.ant-layout-content {
        max-height: initial;
        overflow-y: visible;
    }
}
@media screen and (max-width: 767px ) {
    #LINE button.ant-modal-close{
        top: 6px;
    }
    #LINE .ant-list-item-meta{
        flex-direction: column;
        align-items: center!important;
    }
    #LINE .ant-list-item-meta-content{
        min-width: initial;
    }
    #LINE .ant-list-item-meta-avatar{
        min-width: initial;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    #LINE .ant-tag{
        font-size: 14px;
        padding: 2px 5px;
    }
    #LINE .ant-tag{
        margin: 8px;
    }
}
@media screen and (max-width: 588px ) {
    #LINE :where(.css-dev-only-do-not-override-mxhywb).ant-radio-wrapper .ant-radio{
        align-self: flex-start;
        margin-top: 4px;
    }
}