@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinnerDiv{
  display: flex;
  justify-content: center;
}
.loadingSpinner{
  width: 28px;
  height: 28px;
  border: 14px solid #f3f3f386; /* Light grey */
  border-top: 14px solid #2d577e; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  background-color: #98b6d3;
}

.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}