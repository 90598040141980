.wpEntityContent{
    display: flex;
    flex-direction: column;
}
.wpEntityForm{
    margin: 8px auto;
    padding: 15px 40px;
    max-width: 875px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}
.wpEntityFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpEntityH4 {
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpEntityH6 {
    margin: 1px 0 8px;
    flex: 2;
    min-width: 83px;
    text-align: left;
}
.wpEntitySpaceSendBtn{
    flex: 9;
    width: 100%;
    /* min-width: 370px; */
    padding-right: 10px;
    text-align: left;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
}
.wpEntityDivObject{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
/* .wpEntityLabel, .wpEntityLabel span{
    background-color: #e3e3e3;
} */
.wpEntityDivFilterByTags{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpEntityDivFilterByTags > button:nth-child(1){
    margin: 0 5px 5px 0; 
}
.wpEntityDropdown{
    width: fit-content;
}
.wpEntityDivAudience, .wpEntityDivTags{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpEntityeModalAudience, .wpEntityeModalTags{
    display: flex;
    justify-content: center;
    top: 8vh;
}
.wpEntityDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpEntitySpace{
    flex: 9;
    text-align: left;
}
.wpEntitySpaceAudience, .wpEntitySpaceTags{
    flex-direction: column;
    align-items: flex-start;
}
.wpEntitySpaceAudience{
    margin-bottom: .8rem;
}
.wpEntitySpaceTime{
    flex: 9;
    min-width: 370px;
    text-align: left;
}
.wpEntityInputObject{
    height: initial;
    line-height: initial;
    padding: 4px 11px 4px;
}
.wpEntityRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpEntityRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpEntityRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpEntityRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpEntityRadioSchedule .ant-radio{
    align-self: start!important;
}

.wpEntityRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpEntityRadioPicker{
    display: flex;
    flex-wrap: wrap;
}
.wpEntityRadioDatePicker, .wpEntityRadioTimePicker{
    margin: 6px 6px 6px 0;
}
.wpEntityFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpEntitySpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpEntitySpaceCheckbox{
    display: flex;
}
.wpEntitySpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpEntityFormItemLineCastInterface{
    width: 100%;
}
.wpEntitySpaceLineCastInterface{
    width: 100%;
}
.wpEntitySpaceLineCastInterface > div{
    width: 100%;
}
.wpEntityAudienceContainer, .wpEntityTagsContainer, .wpEntityAudienceDetailContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpEntityAudienceDetailContainer{
    width: 100%;
    justify-content: flex-start;
}
.wpEntityAudience, .wpEntitySettingAudience{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.wpEntitySettingAudience{
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
.wpEntitySubTitle{
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.wpEntityTags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 5px 5px 0!important;
    padding: 2px 5px;
    font-size: 14px;
}
.wpEntityTagsTags span[role="img"]{
    color: #1d39c4;
}
.wpEntityTagsTags span[role="img"]:hover{
    color: #adc6ff;
}
.wpEntityTag{
    width: fit-content;
    min-width: initial;
    max-width: none;
    padding: 4px 8px;
    align-items: center;
    background-color: #33658A;
    color:#FFF;
}
.wpEntityNoTags{
    align-items: center;
    background-color: #6ea2c9;
    color:#FFF;
}
.wpEntitySettingTag{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
@media screen  and (max-width: 767px) {
    .wpEntityForm{
        padding: 10px 20px;
    }
    .wpEntitySpaceTime{
        min-width: 280px;
    } 
    .wpEntityAudienceDetailContainer{
        width: 100%;
    }
}
@media screen  and (max-width: 540px) {
    .wpEntityForm{
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .wpEntityRadioDatePicker, .wpEntityRadioTimePicker{
        margin: 6px 6px 6px 0;
    }
}
/* @media screen  and (max-width: 300px) {
    .wpEntityeModalAudience{
        justify-content: center;
    }
} */