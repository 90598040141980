.wpReadParentChildCourseChildrenListLayout{
    padding: 5px;
}
.wpReadParentChildCourseChildrenListTitleDiv{
    margin: 8px auto;
    padding-top: 15px;
    /* max-width: 460px; */
    max-width: 875px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wpReadParentChildCourseChildrenListH4 {
    font-weight: 600;
    margin-bottom: .5rem;
    /* margin: 1rem 0; */
    width: 100%;
    /* max-width: 540px; */
    /* min-width: 370px; */
    /* line-height: 2.2rem; */
}
.wpReadParentChildCourseChildrenListForm{
    margin: 8px auto 0;
    padding: 15px 40px 0;
    max-width: 435px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 80vh; */
}
.wpReadParentChildCourseChildrenListFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}

.wpReadParentChildCourseChildrenListH6 {
    /* margin: 1px 0 8px; */
    margin: 0;
    flex: 2;
    min-width: 83px;
    text-align: left;
}
.wpReadParentChildCourseChildrenListSpaceSendBtn{
    flex: 9;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    /* min-width: 370px; */
    /* padding-right: 10px; */
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.wpReadParentChildCourseChildrenListSpaceUpdateBtn{
    margin-top: .5rem;
    margin-bottom: .5rem;
}
.wpReadParentChildCourseChildrenListSpaceSendBtn div, .wpReadParentChildCourseChildrenListSpaceSendBtn span{
    font-size: 14px!important;
}
.wpReadParentChildCourseChildrenListSendBtn{
    margin: 4px;
}
.wpReadParentChildCourseChildrenListSpaceDropdown{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
/* .wpReadParentChildCourseChildrenListSpaceDropdown h6{
    margin-bottom: 1px!important;
} */
.wpReadParentChildCourseChildrenListDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpReadParentChildCourseChildrenListFlex1{
    flex: 2;
    text-align: left;
}
.wpReadParentChildCourseChildrenListFlex2{
    flex: 9;
    text-align: left;
}
.wpReadParentChildCourseChildrenListSpaceTime{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpReadParentChildCourseChildrenListRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpReadParentChildCourseChildrenListRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpReadParentChildCourseChildrenListRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpReadParentChildCourseChildrenListRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpReadParentChildCourseChildrenListRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpReadParentChildCourseChildrenListDivTimePicker{
    display: flex;
    flex-wrap: wrap;
}
.wpReadParentChildCourseChildrenListDatePicker{
    margin-right: 8px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpReadParentChildCourseChildrenListTimePicker{
    margin-right: 8px;
    min-width: 250px;
    padding: 4px 15px;
}
.wpReadParentChildCourseChildrenListFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpReadParentChildCourseChildrenListSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpReadParentChildCourseChildrenListSpaceCheckbox{
    display: flex;
}
.wpReadParentChildCourseChildrenListSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpReadParentChildCourseChildrenListFormItemLineCastInterface{
    width: 100%;
}
.wpReadParentChildCourseChildrenListSpaceLineCastInterface{
    width: 100%;
}
.wpReadParentChildCourseChildrenListSpaceLineCastInterface > div{
    width: 100%;
}
.wpReadParentChildCourseChildrenListTitleContent{
    margin: 8px auto!important;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wpReadParentChildCourseChildrenListFilterConsultContent{
    margin-bottom: .6rem!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
/* .wpReadParentChildCourseChildrenListFilterConsultContent h1, .wpReadParentChildCourseChildrenListFilterConsultContent h2, .wpReadParentChildCourseChildrenListFilterConsultContent h3, .wpReadParentChildCourseChildrenListFilterConsultContent h4, .wpReadParentChildCourseChildrenListFilterConsultContent h5, .wpReadParentChildCourseChildrenListFilterConsultContent h6{
    margin-bottom: .6em;
} */
.wpReadParentChildCourseChildrenListFilterConsultContent div, .wpReadParentChildCourseChildrenListFilterConsultContent span, .wpReadParentChildCourseChildrenListFilterConsultContent p {
    font-size: 16px;
}
.wpReadParentChildCourseChildrenListFilterConsultContentQuery h5{
    /* color: #1677ff; */
    font-weight: 800;
}
.wpReadParentChildCourseChildrenListFilterConsultSearchSec{
    width: 80%;
    max-width: 875px;
    min-width: 875px;
    /* height: 70vh; */
    margin: 10px auto;
    padding: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 3px -1px 10px -1px #00152933;
    border-radius: 3px;
    background-color: #F9F9F9;
}
.wpReadParentChildCourseChildrenListFilterConsultTitle h4{
    font-weight: 800;
    color: #001529;
}
.wpReadParentChildCourseChildrenListFilterConsultList{
    width: 100%;
    /* border: 1px solid #1677ff; */
    border-radius: 5px;
    padding: 10px;
}
.wpReadParentChildCourseChildrenListFilterConsultList ul{
    max-height: 54vh;
    overflow-y: scroll;
    padding: 0 12px!important;
    margin-bottom: .5rem!important;
}
.wpReadParentChildCourseChildrenListItemAction ul{
    overflow-y: hidden;
}
/* .wpReadParentChildCourseChildrenListFilterConsultList span{
    color: #1677ff;
} */
.wpReadParentChildCourseChildrenListFilterConsultListItemMeta{
    flex-wrap: wrap;
    flex-direction: column;
    /* cursor: pointer; */
    /* padding: 0 20px 0 0; */
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-width: 0 1px 0 0;
}
/* .wpReadParentChildCourseChildrenListFilterConsultListItemMeta:hover{
    background-color: #dedede;
} */
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpace{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionSpaceSpace{
    display: flex;
    flex-direction: column;
    gap: 0!important;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemName {
    width: 240px;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemCategory{
    width: 300px;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemDescription{
    width: 360px;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemPriority{
    width: 145px;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionText{
    font-weight: 400;
}

.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabel{
    font-weight: 600;
    color: #001529cc;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionName{
    font-weight: 800;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabelName, .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabelCategory, .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.wpReadParentChildCourseChildrenListFilterConsultDiv{
    display: flex;
    flex-direction: row;
}
.wpReadParentChildCourseChildrenListFilterConsultIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpReadParentChildCourseChildrenListFilterConsultIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.wpReadParentChildCourseChildrenListSpaceInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpReadParentChildCourseChildrenListInput{
    width: 150px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpReadParentChildCourseChildrenListInputMaximum{
    width: 80px;
    min-width: 80px;
    padding: 4px 15px;
}
.wpReadParentChildCourseChildrenListIconDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.wpReadParentChildCourseChildrenListIcon{
    cursor: pointer;
    /* margin: 2px 1px 5px 5px!important; */
    /* margin: 12px 0 0 12px; */
    margin: 12px;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpReadParentChildCourseChildrenListIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.wpReadParentChildCourseChildrenListInputName{
    width: 100%!important;
    max-width: 150px;
}
.wpReadParentChildCourseChildrenListInputBirthday{
    width: 100%!important;
    max-width: 180px;
}
.wpReadParentChildCourseChildrenListInputEmail{
    width: 100%!important;
    max-width: 200px;
}
.wpReadParentChildCourseChildrenListInputTel{
    width: 100%!important;
    max-width: 200px;
}
/* .wpReadParentChildCourseFilterConsultCourseDescriptionDivUpadate{
    justify-content: flex-start;
} */
.wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate{
    justify-content: flex-start;
    margin-bottom: 1rem!important;
}
.wpReadParentChildCourseChildrenListItemSendBtn{
    margin: 8px;
}
@media screen and (max-width: 1510px) {
    .wpReadParentChildCourseChildrenListFilterConsultList ul{
        max-height: 66vh;
    }
    /* .wpReadParentChildCourseChildrenListFilterConsultSearchSec{
        height: 83vh;
    } */
    .wpReadParentChildCourseChildrenListFilterConsultList ul{
        max-height: 58vh;
    }
}
@media screen and (max-width: 1090px) {
    .wpReadParentChildCourseChildrenListFilterConsultSearchSec{
        min-width: initial;
    }
}
@media screen and (max-width: 1083px) {
    .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdate{
        width: 100%;
    }
    .wpReadParentChildCourseChildrenListInputName{
        max-width: 250px;
        min-width: 150px;
    }
    .wpReadParentChildCourseChildrenListInputBirthday{
        max-width: 300px;
        min-width: 150px;
    }
    .wpReadParentChildCourseChildrenListInputEmail{
        max-width: 350px;
        min-width: 150px;
    }
    .wpReadParentChildCourseChildrenListInputTel{
        max-width: 350px;
        min-width: 150px;
    }
}
@media screen  and (max-width: 991px) {
    .wpReadParentChildCourseChildrenListFilterConsultSearchSec{
        width: 95%;
    }
}
@media screen  and (max-width: 767px) {
    .wpReadParentChildCourseChildrenListForm{
        padding: 10px 20px;
    }
    .wpReadParentChildCourseChildrenListTitleDiv{
        margin: 0 auto;
        padding-top: 0;
    }
    .wpReadParentChildCourseChildrenListFilterConsultContent div, .wpReadParentChildCourseChildrenListFilterConsultContent span, .wpReadParentChildCourseChildrenListFilterConsultContent p {
        font-size: 14px;
    }
    .wpReadParentChildCourseChildrenListFilterConsultContentQuery h5{
        font-size: 18px;
    }
    .wpReadParentChildCourseChildrenListFilterConsultSearchSec{
        width: 100%;
    }
    .wpReadParentChildCourseChildrenListFilterConsultList{
        padding: 0px 8px;
    }
    .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionDiv{
        width: 100%;
        /* flex-direction: column; */
    }
    .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem {
        margin: 8px 4px;
        /* width: 100%; */
        /* justify-content: flex-start; */
    }
    .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemUpdateBtn{
        justify-content: center;
    }
    .wpReadParentChildCourseChildrenListH4 {
        padding-top: 15px;
    }
    .wpReadParentChildCourseChildrenListItemAction{
        flex-direction: column;
    }
    .wpReadParentChildCourseChildrenListIcon{
        margin: 2px 0 0 12px;
    }
    /* .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItemTitle{
        flex-direction: column;
    } */
    /* .wpReadParentChildCourseChildrenListSpaceTime{
        min-width: 280px;
    } */
    .wpReadParentChildCourseChildrenListFilterConsultCourseDescriptionItem{
        margin: 1px;
    }
    .wpReadParentChildCourseChildrenListSpaceApplyAbsenceBtn{
        padding: 0;
    }
    .wpReadParentChildCourseChildrenListFilterConsultSearchSec{
        padding: 18px 12px;
        /* height: 100%; */
    }
    .wpReadParentChildCourseChildrenListFilterConsultListItemMeta{
        border: none;
    }
    .wpReadParentChildCourseChildrenListSpaceSendBtn{
        margin-top: 0.6rem;
    }
}
@media screen  and (max-width: 540px) {
    .wpReadParentChildCourseChildrenListForm{
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .wpReadParentChildCourseChildrenListDatePicker{
        margin-bottom: 8px;
    }
}
