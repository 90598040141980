.rce-container-input{
    min-width: 100%;
}
.rce-dropdown{
    min-width: 10%;
    padding: 5px!important;
}
.rce-dropdown ul li:hover{
    background-color: #1677FE;
    border-radius: 5px;
}
.rce-dropdown ul li:hover a{
    /* background-color: #000; */
    color: #FFF!important;
}
.rce-mbox-text{
    text-align: left;
}
.rce-mbox-video--video{
    /* margin-top: -14px; */
    border-radius: 0;
}
.rce-mbox-video--img{
    background: #000;
}
/* File訊息區塊最大寬度 */
.rce-mbox-file>button {
    max-width: 393px!important;
}
.rce-input{
    height: 82px;
    z-index: 5;
    margin-right: 25px;
}
.rce-input-buttons{
    margin: 3px;
    justify-content: flex-end;
}
.rce-button{
    border-radius: 3px;
}
.rce-citem-body--top{
    flex-wrap: wrap;
}
.rce-container-citem{
    width: 100%;
    min-width: initial!important;
}
/* 動態設置訊息框樣式 */
.CHAT .rce-mbox{
    background-color: #fff;
}
.CHAT .rce-mbox-right-notch, .CHAT .rce-mbox-left-notch{
    fill: #fff;;
}
.LINE .rce-mbox{
    background-color: #ccffcc;
}
.LINE .rce-mbox-right-notch, .LINE .rce-mbox-left-notch{
    fill: #ccffcc;;
}
.FACEBOOK .rce-mbox{
    background-color: #d3dcf5;
}
.FACEBOOK .rce-mbox-right-notch, .FACEBOOK .rce-mbox-left-notch{
    fill: #d3dcf5;;
}
.EMAIL .rce-mbox{
    background-color: #ffebd4;
}
.EMAIL .rce-mbox-right-notch, .EMAIL .rce-mbox-left-notch{
    fill: #ffebd4;;
} /* END 動態設置訊息框樣式 */
@media screen and (max-width: 991px) {
    .rce-container-citem{
        width: 80%;
    }
}
@media screen and (max-width: 767px) {
    .CHAT.left, .LINE.left, .FFACEBOOK.left, .EMAIL.left{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .CHAT.right, .LINE.right, .FFACEBOOK.right, .EMAIL.right{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .rce-container-mbox {
        width: fit-content;
    }
    .rce-container-citem{
        width: 100%;
    }
    /* File訊息區塊最大寬度 */
    .rce-mbox-file>button {
        max-width: 230px!important;
    }
    .rce-mbox-audio {
        max-width: 230px;
    }
    .rce-mbox-audio audio{
        max-width: 230px;
    }
}
@media screen and (max-width:576px){
    .rce-container-input{
        /* min-width: 90%; */
        min-width: initial;
    }
    .rce-input{
        width: 100%;
        margin-right: 28px;
        flex: 2;
        z-index: 5;
    }
    .rce-input-buttons{
        width: 100%;
        flex: 1.3;
    }
}