.wpSetParentChildCourseForm{
    /* margin: 8px auto; */
    /* padding: 20px 40px; */
    /* max-width: 505px; */
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* min-height: 80vh; */
}
.wpSetParentChildCourseContent{
    /* margin: 8px auto!important; */
    /* max-width: 505px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 292px; */
}
.wpSetParentChildCourseContent>section{
    margin: 13px auto 8px;
    max-width: 460px;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wpSetParentChildCourseOuterSection{
    width: 100%;
    max-width: 1024px!important;
    display: flex;
    flex-direction: row!important;
    flex-wrap: wrap;
    justify-content: center;
}
.wpSetParentChildCourseOuterSectionAddClass{
    margin: 0 auto!important;
}
.wpSetParentChildCourseSection{
    width: 100%;
    /* padding: 0 40px; */
}
.wpSetParentChildCourseBtnSection{
    flex-direction: row!important;
    max-width: fit-content!important;
}
.wpSetParentChildCourseSectionTitle{
    padding-top: 15px;
}
.wpSetParentChildCourseSectionCourseIdName .wpSetParentChildCourseFormItem{
    margin-bottom: 1rem!important;
}
.wpSetParentChildCourseSectionCourseIdName .wpSetParentChildCourseDivNameAndAddBtn  .wpSetParentChildCourseFormItem{
    margin-bottom: 0!important;
}
.wpSetParentChildCourseSectionAddClass{
    max-width: 460px;
    padding: 10px 20px;
}
.wpSetParentChildCourseDivClass{
    padding: 15px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    background-color: #FAFAFA;
}
/* .wpSetParentChildCourseSection:last-child{
    padding-bottom: 20px;
} */
.wpSetParentChildCourseFormItem{
    width: 100%;
    max-width: 540px;
    /* margin: auto!important; */
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpSetParentChildCourseDivClass .wpSetParentChildCourseFormItem{
    margin-bottom: 0.6rem!important;
}
.wpSetParentChildCourseFormItemCompensate, .wpSetParentChildCourseFormItemName, .wpSetParentChildCourseFormItemAddClass{
    margin: 0!important;
}
.wpSetParentChildCourseFormItemCompensate, .wpSetParentChildCourseFormItemName{
    /* margin-right: 8px!important; */
    flex: 5;
    display: flex;
    align-items: center;
}
.wpSetParentChildCourseFormItemAddClass{
    flex: 3;
}
.wpSetParentChildCourseFormItemSendBtn{
    margin: auto!important;
}
.wpSetParentChildCourseH4 {
    font-weight: 600;
    /* margin-bottom: .6rem; */
    margin-bottom: 0;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpSetParentChildCourseH6 {
    /* margin: 1px 0 8px; */
    margin: 0 8px 0 0;
    flex: 2;
    /* min-width: 95px; */
    text-align: left;
}
.wpSetParentChildCourseDivNameAndAddBtn{
    width: 100%;
    /* margin-bottom: 1rem; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpSetParentChildCourseSpaceSendBtn{
    flex: 9;
    width: 100%;
    margin-top: .5rem;
    /* margin-bottom: 0.3rem; */
    /* min-width: 370px; */
    /* padding-right: 10px; */
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.wpSetParentChildCourseSpaceUpdateBtn{
    margin: 8px;
}
.wpSetParentChildCourseSpaceUpdateBtnText div{
    font-size: 14px!important;
}
/* .wpSetParentChildCourseSpaceSendBtn button{
    height: initial;
}
.wpSetParentChildCourseSpaceSendBtn button div{
    font-size: initial;
} */
.wpSetParentChildCourseSpaceSendBtnAdd{
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;
}
/* .wpSetParentChildCourseSpaceSendBtnAdd button div{
    font-size: 14px;
    padding: 2px 1px;
} */
.wpSetParentChildCourseOuterDivInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetParentChildCourseSpaceInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetParentChildCourseSpaceInputFormal{
    flex-wrap: nowrap;
}
.wpSetParentChildCourseInputFormal{
    margin-right: 8px;
}
.wpSetParentChildCourseDivDeleteOutlined{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.wpSetParentChildCourseDelIcon{
    cursor: pointer;
    margin: 2px 1px 1px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpSetParentChildCourseDelIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
/* .wpSetParentChildCourseSpaceInputName{
    flex-wrap: wrap;
    align-items: initial;
} */
/* .wpSetParentChildCourseDivInput{
    display: flex;
    flex-wrap: wrap;
} */
.wpSetParentChildCourseInput{
    width: 324px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpSetParentChildCourseInputName{
    width: 340px;
    min-width: 250px;
    padding: 4px 15px;
}
.wpSetParentChildCourseInputIntro{
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpSetParentChildCourseInputIntro.wpSetParentChildCourseInputIntroUpdate{
    width: 100%;
}
.wpSetParentChildCourseInputPrice{
    width: 120px;
    min-width: 120px;
    padding: 4px 15px;
}
.wpSetParentChildCourseInputPriceUpdate{
    max-width: 180px;
}
.wpSetParentChildCourseInputPlace{
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpSetParentChildCourseInputMaximum{
    width: 80px;
    min-width: 80px;
    padding: 4px 15px;
}
.wpSetParentChildCourseSpaceDropdown{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
/* .wpSetParentChildCourseSpaceDropdown h6{
    margin-bottom: 1px!important;
} */
.wpSetParentChildCourseDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpSetParentChildCourseFlex1{
    flex: 2;
    text-align: left;
}
.wpSetParentChildCourseFlex2{
    flex: 9;
    text-align: left;
}
.wpSetParentChildCourseSpaceTime{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpSetParentChildCourseRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpSetParentChildCourseRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpSetParentChildCourseRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpSetParentChildCourseRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpSetParentChildCourseRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpSetParentChildCourseDivTimePicker{
    display: flex;
    flex-wrap: wrap;
}
.wpSetParentChildCourseDatePicker{
    /* margin-right: 8px; */
    width: 150px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpSetParentChildCourseTimePicker{
    /* margin-right: 8px; */
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpSetParentChildCourseFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpSetParentChildCourseSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpSetParentChildCourseSpaceCheckbox{
    display: flex;
}
.wpSetParentChildCourseSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpSetParentChildCourseFormItemLineCastInterface{
    width: 100%;
}
.wpSetParentChildCourseSpaceLineCastInterface{
    width: 100%;
}
.wpSetParentChildCourseSpaceLineCastInterface > div{
    width: 100%;
}
.wpSetParentChildCourseFilterConsultContent{
    margin: 0.8rem 0 2em!important;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
/* .wpSetParentChildCourseFilterConsultContent h1, .wpSetParentChildCourseFilterConsultContent h2, .wpSetParentChildCourseFilterConsultContent h3, .wpSetParentChildCourseFilterConsultContent h4, .wpSetParentChildCourseFilterConsultContent h5, .wpSetParentChildCourseFilterConsultContent h6{
    margin-bottom: .6em;
} */
.wpSetParentChildCourseFilterConsultContent div, .wpSetParentChildCourseFilterConsultContent span, .wpSetParentChildCourseFilterConsultContent p {
    font-size: 16px;
}
.wpSetParentChildCourseFilterConsultContentQuery h5{
    /* color: #1677ff; */
    font-weight: 800;
}
.wpSetParentChildCourseFilterConsultSearchSec{
    width: 80%;
    max-width: 875px;
    min-width: 875px;
    height: 50vh;
    margin: 10px auto;
    padding: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 3px -1px 10px -1px #00152933;
    border-radius: 3px;
    background-color: #F9F9F9;
}
.wpSetParentChildCourseFilterConsultTitle h4{
    font-weight: 800;
    color: #001529;
}
.wpSetParentChildCourseFilterConsultList{
    width: 100%;
    /* border: 1px solid #1677ff; */
    border-radius: 5px;
    padding: 10px;
}
.wpSetParentChildCourseFilterConsultList ul{
    max-height: 40vh;
    overflow-y: scroll;
    padding: 0 12px!important;
}
/* .wpSetParentChildCourseFilterConsultList span{
    color: #1677ff;
} */
.wpSetParentChildCourseFilterConsultListItemMeta{
    flex-wrap: wrap;
    flex-direction: column;
    /* cursor: pointer; */
}
/* .wpSetParentChildCourseFilterConsultListItemMeta:hover{
    background-color: #dedede;
} */
.wpSetParentChildCourseFilterConsultCourseDescriptionDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionPanel .wpSetParentChildCourseFilterConsultCourseDescriptionQueryDiv{
    border-bottom: 1px solid #ddd;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionPanel .wpSetParentChildCourseFilterConsultCourseDescriptionQueryDiv:last-child{
    border-bottom: initial;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItem{
    /* width: 100%; */
    margin: 8px;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: flex-start;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemName {
    width: 240px;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemCategory{
    width: 300px;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemDescription{
    width: 360px;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemPriority{
    width: 145px;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionText{
    width: 240px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionLabel{
    font-weight: 800;
    color: #001529cc;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionName{
    margin-right: 8px;
    font-weight: 800;
    text-align: left;
    white-space: nowrap;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionLabelName, .wpSetParentChildCourseFilterConsultCourseDescriptionLabelCategory, .wpSetParentChildCourseFilterConsultCourseDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.wpSetParentChildCourseFilterConsultDiv{
    display: flex;
    flex-direction: row;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.wpSetParentChildCourseFilterConsultIconDiv{
    margin: 8px 4px;
    display: flex;
    justify-content: flex-end;
}
.wpSetParentChildCourseFilterConsultIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpSetParentChildCourseFilterConsultIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDiv{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDivUpdate{
    flex-direction: column;
    align-items: flex-start;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle{
    max-width: 315px;
    min-width: 250px;
    /* max-width: 70%; */
}

.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIntro{
    max-width: initial;
    width: 100%;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDivUpdate{
    align-items: flex-start;
    flex-direction: row;
}
.wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDivUpdate{
    flex-direction: column;
}
@media screen and (max-width: 1510px) {
    .wpSetParentChildCourseFilterConsultSearchSec{
        height: 57vh;
    }
}
@media screen and (max-width: 1220px) {
    .wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDiv{
        align-items: flex-start;
    }
    .wpSetParentChildCourseFilterConsultCourseDescriptionText{
        width: initial;
    }
}
@media screen and (max-width: 1106px) {
    .wpSetParentChildCourseOuterSection{
        justify-content: center;
    }
}
@media screen and (max-width: 1090px) {
    .wpSetParentChildCourseFilterConsultSearchSec{
        min-width: initial;
    }
}
@media screen  and (max-width: 900px) {
    .wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleIdNameDiv{
        max-width: 335px;
    }
    .wpSetParentChildCourseFilterConsultCourseDescriptionItemTitle{
        max-width: 95%;
    }
}
@media screen  and (max-width: 767px) {
    .wpSetParentChildCourseContent>section{
        margin: 8px 0;
    }
    .wpSetParentChildCourseH4 {
        margin-top: .3rem;
    }
    .wpSetParentChildCourseFilterConsultIcon{
        margin: 2px 5px 2px 1px!important;
    }
    .wpSetParentChildCourseForm{
        /* padding: 10px 20px; */
        padding: 5px; 
    }
    .wpSetParentChildCourseFilterConsultContent div, .wpSetParentChildCourseFilterConsultContent span, .wpSetParentChildCourseFilterConsultContent p {
        font-size: 14px;
    }
    /* .wpSetParentChildCourseFilterConsultContentQuery{
        min-width: 292px;
    } */
    .wpSetParentChildCourseFilterConsultContentQuery h5{
        font-size: 18px;
    }
    .wpSetParentChildCourseFilterConsultSearchSec{
        width: 100%;
        /* min-width: 292px; */
    }
    .wpSetParentChildCourseFilterConsultList{
        padding: 0px 8px;
    }
    .wpSetParentChildCourseFilterConsultCourseDescriptionDiv{
        width: 100%;
        /* flex-direction: column; */
    }
    .wpSetParentChildCourseFilterConsultCourseDescriptionItem {
        margin: 8px 4px;
        /* width: 100%; */
        /* justify-content: flex-start; */
    }
    .wpSetParentChildCourseFilterConsultCourseDescriptionItemUpdateBtn{
        justify-content: center;
    }
    .wpSetParentChildCourseContent{
        max-width: initial;
    }
    .wpSetParentChildCourseSection{
        /* min-width: 300px; */
        padding: 0;
    }
    /* .wpSetParentChildCourseSectionCourseIdName{
        padding: 0 20px 0;
    } */
    .wpSetParentChildCourseSectionAddClass{
        padding: 0.6rem 0 0;
    }
    /* .wpSetParentChildCourseDivClass{
        min-width: 292px;
    } */
    .wpSetParentChildCourseDelIcon{
        margin: 5px!important;
    }
    .wpSetParentChildCourseInputPlace{
        width: 100%;
        min-width: 252px;
    }
    .wpSetParentChildCourseTimePicker{
        width: 100%;
        min-width: 252px;
    }
    .wpSetParentChildCourseFilterConsultCourseDescriptionItemTitleDiv{
        flex-direction: column-reverse;
    }
    /* .wpSetParentChildCourseSpaceTime{
        min-width: 280px;
    } */
    .wpSetParentChildCourseFilterConsultSearchSec{
        height: 100%;
        padding: 18px 12px;
    }
}
@media screen  and (max-width: 540px) {
    /* .wpSetParentChildCourseForm{
        padding: 5px;
    } */
    .wpSetParentChildCourseInput{
        width: 100%;
    }
    .wpSetParentChildCourseInputName{
        width: 100%;
    }
    .wpSetParentChildCourseFilterConsultCourseDescriptionText{
        max-width: 125px;
    }
}
@media screen  and (max-width: 431px) {
    /* .wpSetParentChildCourseDatePicker{
        margin-bottom: 8px;
    } */
}
