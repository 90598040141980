.chatRoomContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.chatRoomUserList{
    width: 100%;
    min-width: 360px;
    height: 92vh;
    /* height: 100%; */
    overflow-y: scroll;
    background-color: #F5F5F5;
    /* box-shadow: 3px 0px 10px -3px #33333388; */
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
}
.chatRoomChatRoomContainer{
    width: 100%;
    min-width: 360px;
    height: 92vh;
    /* height: 100%; */
    overflow-y: scroll;
    /* box-shadow: 3px 0px 10px -3px #33333388; */
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
}
.chatRoomChatRoomContainerHidden{
    display: none!important;
}
.chatRoomUserDetail{
    width: 100%;
    min-width: 360px;
    height: 92vh;
    /* height: 100%; */
    overflow-x: hidden;
    overflow-y: scroll;    
    background-color: #F5F5F5;
    /* box-shadow: 3px 0px 10px -3px #33333388; */
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1510px) {
    .chatRoomUserList, .chatRoomChatRoomContainer, .chatRoomUserDetail{
        height: 100vh;
    }
}
@media screen and (max-width: 991px) {
    .chatRoomContainer{
        flex-direction: column;
    }
    /* .chatRoomUserList, .chatRoomChatRoomContainer, .chatRoomUserDetail{
        height: 100vh;
    } */
    .chatRoomUserList, .chatRoomChatRoomContainer, .chatRoomUserDetail{
        height: fit-content;
        margin-bottom: 8px;
        position: relative;
        max-width: 100%;
        min-height: 1px;
        border: 1px solid #F5F5F5;
    }
    .chatRoomChatRoomContainer{
        margin-bottom: 0;
    }
}
@media screen and (max-width: 767px) {
    .chatRoomUserList, .chatRoomChatRoomContainer, .chatRoomUserDetail{
        min-width: 280px;
    }
}