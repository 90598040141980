.userListFilter{
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
}
.userListFilterDropdown{
    background-color: #1677FE!important;
    border-radius: 5px;
    padding: 7px 14px;
    height: initial;
}
.userListFilterDropdown:hover{
    background-color: #4592FE!important;
    opacity: 1;
}
