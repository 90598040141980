.wpTrainingCourseContent{
    display: flex;
    flex-direction: column;
}
.wpTrainingCourseForm{
    margin: 8px auto;
    padding: 15px 40px;
    max-width: 875px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}
.wpTrainingCourseFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpTrainingCourseH4 {
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpTrainingCourseH6 {
    margin: 1px 0 8px;
    flex: 2;
    min-width: 83px;
    text-align: left;
}
.wpTrainingCourseSpaceSendBtn{
    flex: 9;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    /* min-width: 370px; */
    padding-right: 10px;
    text-align: left;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
}
.wpTrainingCourseDivObject{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
/* .wpTrainingCourseLabel, .wpTrainingCourseLabel span{
    background-color: #e3e3e3;
} */
.wpTrainingCourseDivFilterByTemplate{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpTrainingCourseDivFilterByTemplate > button:nth-child(1){
    margin: 0 5px 5px 0; 
}
.wpTrainingCourseDropdown{
    width: fit-content;
}
.wpTrainingCourseDivAudience, .wpTrainingCourseDivTemplate{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpTrainingCourseeModalAudience, .wpTrainingCourseeModalTemplate{
    display: flex;
    justify-content: center;
    top: 8vh;
}
.wpTrainingCourseDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpTrainingCourseSpace{
    flex: 9;
    text-align: left;
}
.wpTrainingCourseSpaceAudience, .wpTrainingCourseSpaceTemplate{
    flex-direction: column;
    align-items: flex-start;
}
.wpTrainingCourseSpaceAudience{
    margin-bottom: .8rem;
}
.wpTrainingCourseSpaceTime{
    flex: 9;
    min-width: 370px;
    text-align: left;
}
.wpTrainingCourseInputObject{
    height: initial;
    line-height: initial;
    padding: 4px 11px 4px;
}
.wpTrainingCourseRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpTrainingCourseRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpTrainingCourseRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpTrainingCourseRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpTrainingCourseRadioSchedule .ant-radio{
    align-self: start!important;
}

.wpTrainingCourseRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpTrainingCourseRadioPicker{
    display: flex;
    flex-wrap: wrap;
}
.wpTrainingCourseRadioDatePicker, .wpTrainingCourseRadioTimePicker{
    margin: 6px 6px 6px 0;
}
.wpTrainingCourseFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpTrainingCourseSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpTrainingCourseSpaceCheckbox{
    display: flex;
}
.wpTrainingCourseSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpTrainingCourseFormItemLineCastInterface{
    width: 100%;
}
.wpTrainingCourseSpaceLineCastInterface{
    width: 100%;
}
.wpTrainingCourseSpaceLineCastInterface > div{
    width: 100%;
}
.wpTrainingCourseAudienceContainer, .wpTrainingCourseTemplateContainer, .wpTrainingCourseAudienceDetailContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpTrainingCourseAudienceDetailContainer{
    width: 100%;
    justify-content: flex-start;
}
.wpTrainingCourseAudience, .wpTrainingCourseSettingAudience{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.wpTrainingCourseSettingAudience{
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
.wpTrainingCourseSubTitle{
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.wpTrainingCourseTags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 5px 5px 0!important;
    padding: 2px 5px;
    font-size: 14px;
}
.wpTrainingCourseTemplateTags span[role="img"]{
    color: #1d39c4;
}
.wpTrainingCourseTemplateTags span[role="img"]:hover{
    color: #adc6ff;
}
.wpTrainingCourseTag{
    width: fit-content;
    min-width: initial;
    max-width: none;
    padding: 4px 8px;
    align-items: center;
    background-color: #33658A;
    color:#FFF;
}
.wpTrainingCourseNoTags{
    align-items: center;
    background-color: #6ea2c9;
    color:#FFF;
}
.wpTrainingCourseSettingTag{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
@media screen  and (max-width: 767px) {
    .wpTrainingCourseForm{
        padding: 10px 20px;
    }
    .wpTrainingCourseSpaceTime{
        min-width: 280px;
    } 
    .wpTrainingCourseAudienceDetailContainer{
        width: 100%;
    }
}
@media screen  and (max-width: 540px) {
    .wpTrainingCourseForm{
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .wpTrainingCourseRadioDatePicker, .wpTrainingCourseRadioTimePicker{
        margin: 6px 6px 6px 0;
    }
}
/* @media screen  and (max-width: 300px) {
    .wpTrainingCourseeModalAudience{
        justify-content: center;
    }
} */