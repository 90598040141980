.chatRoomSendEmojiDropdownDiv{
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.chatRoomSendShowEmojiModalBtn{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    z-index: 20;
}
.chatRoomSendShowEmojiModalBtn::after{
    content: "🙂";
}
.chatRoomSendShowEmojiModalBtn:hover::after{
    content: "😀";
}
/* emoji modal位置 */
.chatRoomSendEmojiModal{
    top: 4%!important;
    right: -1.5%;
}
.chatRoomSendDiv{
    width: 98%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* outline: 1px solid #888;
    border-radius: 3px; */
}
.chatRoomSendForm{
    width: 100%;
    /* margin-top: -22px; */
    /* margin-top: -65px; */
}
.chatRoomSendDropdown{
    /* margin-bottom: 8px; */
    display: flex;
    justify-content: flex-end;
}
.chatRoomSendDropdown button{
    z-index: 20;
    background-color: #1677FE!important;
    border-radius: 5px;
}
.chatRoomSendDropdown button:hover{
    background-color: #4592FE!important;
    opacity: 1;
}
.chatRoomSendInput{
    height: 84px;
    border-radius: 2px;
}
.chatRoomSendButtonDiv{
    display: flex;
    flex-direction: row;
}
.chatRoomSendButton{
    background-color: rgb(22, 119, 254);
    color: white;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
    outline: none;
    padding: 8px;
    height: initial;
    position: relative;
    text-align: center;
    transition: all .15s ease;
    -webkit-user-select: none;
    user-select: none;
}
.chatRoomSendUploadButton{
    background-color: rgb(22, 119, 254);
    color: white;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
    outline: none;
    padding: 4px 8px;
    height: initial;
    position: relative;
    text-align: center;
    transition: all .15s ease;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
}
.chatRoomSendButton:hover{
    background-color: #4592FE;
    color: white!important;
}
.chatRoomUploadLabel{
    margin: 0 0 2px 5px;
    background-color: rgb(22, 119, 254);
    color: white;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
    outline: none;
    padding: 8px;
    position: relative;
    text-align: center;
    transition: all .15s ease;
    -webkit-user-select: none;
    user-select: none;    
}
.chatRoomUploadText{
    font-size: 14px;
}
.chatRoomUploadInput{
    display: none;
}
.chatRoomSendUpload{
    display: inline;
}
/* 下方為測試元件 */
.chatRoomSendBtn{
    height: 50px;
    border-radius: 3px;
}
.chatRoomSendBtn textarea{
    height: 50px!important;
    overflow: scroll!important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none; /* Old Firefox */
    scrollbar-width: none;  /* Firefox */
}
.chatRoomSendBtn textarea::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
@media screen and (max-width: 1510px) {
    .chatRoomSendEmojiModal{
        top: 10%!important;
        right: -1.5%;
    }
}
@media screen and (max-width: 1313px) {
    .chatRoomSendEmojiModal{
        top: 10%!important;
        right: -1.5%;
    }
}
@media screen and (max-width: 1024px) {
    .chatRoomSendEmojiModal{
        top: 0%!important;
    }
}
@media screen and (max-width: 991px) {
    .chatRoomSendEmojiModal{
        /* top: 9.5%!important; */
        top: 16.5%!important;
        right: -4.5%;
    }
}
@media screen and (max-width: 767px) {
    .chatRoomSendEmojiModal{
        top: 10%!important;
        right: 0%;
    }
}