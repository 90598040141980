.lineTemplateComponentsLayout{
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
    border-radius: 3px;
    /* max-height: 92vh; */
}
.lineTemplateComponentsOuterContent{
    display: flex;
    /* align-items: center; */
    overflow-y: hidden!important;
}
.lineTemplateComponentsContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.lineTemplateComponentsContent h1, .lineTemplateComponentsContent h2, .lineTemplateComponentsContent h3, .lineTemplateComponentsContent h4, .lineTemplateComponentsContent h5, .lineTemplateComponentsContent h6{
    margin-bottom: .6em;
}
.lineTemplateComponentsContent div, .lineTemplateComponentsContent span, .lineTemplateComponentsContent p {
    font-size: 16px;
}
.lineTemplateComponentsSearchSec{
    width: 80%;
    max-width: 1024px;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* max-height: 78vh;
    overflow-y: scroll; */
}
.lineTemplateComponentsSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.lineTemplateComponentsSearch{
    width: 100%;
    margin: 1% 0 1.2% 2.5%;
}
.lineTemplateComponentsSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.lineTemplateComponentsSearch svg{
    display: flex;
    align-items: center;
}
.lineTemplateComponentsList{
    width: 100%;
}
.lineTemplateComponentsListItemMeta{
    flex-wrap: wrap;
}
.lineTemplateComponentsInputSpace{
    margin: 0.5em;
}
.lineTemplateComponentsDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.lineTemplateComponentsAddIconDiv{
    display: flex;
    flex-direction: row;
}
.lineTemplateComponentsAddIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.lineTemplateComponentsAddIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.lineTemplateComponentsDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.lineTemplateComponentsDescriptionItemName {
    width: 200px;
}
.lineTemplateComponentsDescriptionItemCategory{
    width: 235px;
}
.lineTemplateComponentsDescriptionItemDescription{
    width: 320px;
}
.lineTemplateComponentsDescriptionItemPriority{
    width: 125px;
}
.lineTemplateComponentsDescriptionItemUpdateBtn{
    width: 100%;
}
.lineTemplateComponentsDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.lineTemplateComponentsDescriptionLabel{
    font-weight: 800;
    color: #777;
}
.lineTemplateComponentsDescriptionLabelName, .lineTemplateComponentsDescriptionLabelCategory, .lineTemplateComponentsDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.lineTemplateComponentsDescriptionLabelPriority{
    /* flex: 4; */
    width: 70px;
}
.lineTemplateComponentsDescriptionInputName{
    /* flex: 2; */
    width: 100px;
}
.lineTemplateComponentsDescriptionInputCategory{
    /* flex: 3; */
    width: 135px;
}
.lineTemplateComponentsDescriptionInputDescription{
    /* flex: 4; */
    width: 220px;
}
.lineTemplateComponentsDescriptionInputPriority{
    /* flex: 0.5; */
    width: 55px;
}
@media screen and (max-width: 767px) {
    .lineTemplateComponentsContent div, .lineTemplateComponentsContent span, .lineTemplateComponentsContent p {
        font-size: 14px;
    }
    .lineTemplateComponentsSearchSec{
        width: 100%;
    }
    .lineTemplateComponentsSearch{
        width: 70%;
        min-width: 280px;
    }
    .lineTemplateComponentsDescriptionDiv{
        flex-direction: column;
    }
    .lineTemplateComponentsDescriptionItemDescription{
        width: 260px;
    }
    .lineTemplateComponentsDescriptionInputDescription{
        width: 160px;
    }
    .lineTemplateComponentsDescriptionItem{
        width: 100%;
        margin: 6px;
    }
    .lineTemplateComponentsDescriptionItemTitle{
        flex-direction: column;
    }
    .lineTemplateComponentsAddIcon{
        margin: 5px!important;
    }
}