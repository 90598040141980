/* Popup 最外層 */
/* .modalContainer{
    width: 100%!important;
    position: fixed!important;
    right: 6%!important;
} */
/* Popup 整個內容區塊 */
.modal {
    background-color: white;
    border-radius: 3px;
    background-clip: content-box; /* <---- */
    box-shadow: inset 0 0 0 2px #1c3852;
    background-color: #1C3852;
}
/* Popup Header層 */
/* .modalHeader{
    border-width: 0;
    width: 100%;
    border-radius: 3px;
} */
/* LIFF Popup Messenger標題區塊 */
.modalHeader{   
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
    padding: 10px;
    border: none !important;
    border-radius: 3px 3px 0 0;
    background-color: #1C3852;
    box-shadow: 2px 5px 10px -5px #33333366;
}
/* .modalHeader button{   
    width: 40%;
    margin: auto;
} */
/* LIFF Popup Messenger標題文字 */
.modalHeaderText{
    padding: 8px 10px 0;
    border-radius: 3px 3px 0 0;
    /* background-color: #1E8C96; */
    color: #FFFFFF;
    text-align: center;
}
/* Popup Header內的Banner區塊 */
.modalBanner{
    border-radius: 3px;
    width: 100%;
    height: 100%;
}
/* Banner區塊內的圖片 */
.modalBanner img{
    border-radius: 3px;
    width: 100%;
    height: 100%;
}
/* Popup Body層背景 */
.modalBody{
    background-color: #fafafa;
    border-radius: 3px;
    padding: 0!important;
}
/* Popup關閉圖示 */
.modalClose{
    position: absolute;
    font-weight: 700;
    color: white;
    font-size: 20px;
    top: -25px;
    cursor: pointer;
}
/* 給AutoPopup使用，提示標題樣式 */
.modalTitle{
    width: 100%;
    margin-left: 1.2em;
    text-align: center;
    font-size: 1.2em;
    color: #FA9600;
}
/* 按鈕右邊界 */
.loginButton{
    margin-right: 1em;
}
/* Popup 按鈕 - 登入/註冊文字 */
.loginText {
    color: #666;
}
.loginText:active {
    color: #eee;
}
@media screen and (max-width: 767px) {
    /* .modalContainer{
        justify-content: flex-end!important;
    } */
    .modalContainer h5{
        font-size: 1rem;
    }
    /* LIFF Popup Messenger標題區塊 */
    .modalHeader{
        padding: 0;
    }
    .modalHeaderText{
        padding: 10px 10px 2px;
    }
    /* .modalHeader button {
        width: 178px;
        padding: 2px;
        margin: auto;
        margin-bottom: 10px!important;
        font-size: 12px;
    } */
}




