.lineCastByTagsContent{
    display: flex;
    flex-direction: column;
}
.lineCastByTagsForm{
    margin: 8px auto;
    padding: 20px 40px;
    max-width: 875px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}
.lineCastByTagsFormItem{
    width: 100%;
    max-width: 540px;
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.lineCastByTagsH4 {
    font-weight: 600;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.lineCastByTagsH6 {
    margin: 1px 0 8px;
    flex: 2;
    min-width: 83px;
    text-align: left;
}
.lineCastByTagsSpaceSendBtn{
    flex: 9;
    width: 100%;
    /* min-width: 370px; */
    padding-right: 10px;
    text-align: left;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
}
.lineCastByTagsDivObject{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
/* .lineCastByTagsLabel, .lineCastByTagsLabel span{
    background-color: #e3e3e3;
} */
.lineCastByTagsDivFilterByTags{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.lineCastByTagsDivFilterByTags > button:nth-child(1){
    margin: 0 5px 5px 0; 
}
.lineCastByTagsDropdown{
    width: fit-content;
}
.lineCastByTagsDivAudience, .lineCastByTagsDivTags{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.lineCastByTagseModalAudience, .lineCastByTagseModalTags{
    display: flex;
    justify-content: center;
    top: 8vh;
}
.lineCastByTagsDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.lineCastByTagsSpace{
    flex: 9;
    text-align: left;
}
.lineCastByTagsSpaceAudience, .lineCastByTagsSpaceTags{
    flex-direction: column;
    align-items: flex-start;
}
.lineCastByTagsSpaceAudience{
    margin-bottom: .8rem;
}
.lineCastByTagsSpaceTime{
    flex: 9;
    min-width: 370px;
    text-align: left;
}
.lineCastByTagsInputObject{
    height: initial;
    line-height: initial;
    padding: 4px 11px 4px;
}
.lineCastByTagsRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.lineCastByTagsRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.lineCastByTagsRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.lineCastByTagsRadioSchedule{
    display: flex!important;
    align-items: center;
}
.lineCastByTagsRadioSchedule .ant-radio{
    align-self: start!important;
}

.lineCastByTagsRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.lineCastByTagsRadioPicker{
    display: flex;
    flex-wrap: wrap;
}
.lineCastByTagsRadioDatePicker, .lineCastByTagsRadioTimePicker{
    margin: 6px 6px 6px 0;
}
.lineCastByTagsFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.lineCastByTagsSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.lineCastByTagsSpaceCheckbox{
    display: flex;
}
.lineCastByTagsSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.lineCastByTagsFormItemLineCastInterface{
    width: 100%;
}
.lineCastByTagsSpaceLineCastInterface{
    width: 100%;
}
.lineCastByTagsSpaceLineCastInterface > div{
    width: 100%;
}
.lineCastByTagsAudienceContainer, .lineCastByTagsTagsContainer, .lineCastByTagsAudienceDetailContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.lineCastByTagsAudienceDetailContainer{
    width: 100%;
    justify-content: flex-start;
}
.lineCastByTagsAudience, .lineCastByTagsSettingAudience{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.lineCastByTagsSettingAudience{
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
.lineCastByTagsSubTitle{
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.lineCastByTagsTags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 5px 5px 0!important;
    padding: 2px 5px;
    font-size: 14px;
}
.lineCastByTagsTagsTags span[role="img"]{
    color: #1d39c4;
}
.lineCastByTagsTagsTags span[role="img"]:hover{
    color: #adc6ff;
}
.lineCastByTagsTag{
    width: fit-content;
    min-width: initial;
    max-width: none;
    padding: 4px 8px;
    align-items: center;
    background-color: #33658A;
    color:#FFF;
}
.lineCastByTagsNoTags{
    align-items: center;
    background-color: #6ea2c9;
    color:#FFF;
}
.lineCastByTagsSettingTag{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
@media screen  and (max-width: 767px) {
    .lineCastByTagsForm{
        padding: 10px 20px;
    }
    .lineCastByTagsSpaceTime{
        min-width: 280px;
    } 
    .lineCastByTagsAudienceDetailContainer{
        width: 100%;
    }
}
@media screen  and (max-width: 540px) {
    .lineCastByTagsForm{
        padding: 5px 10px;
    }
}
@media screen  and (max-width: 431px) {
    .lineCastByTagsRadioDatePicker, .lineCastByTagsRadioTimePicker{
        margin: 6px 6px 6px 0;
    }
}
/* @media screen  and (max-width: 300px) {
    .lineCastByTagseModalAudience{
        justify-content: center;
    }
} */