#Customer .rce-button-icon--container{
    display: flex;
    flex-direction: row;
}  

/* 動態設置訊息框樣式 */
.CHAT .rce-mbox{
    background-color: #fff;
}
.CHAT .rce-mbox-right-notch, .CHAT .rce-mbox-left-notch{
    fill: #fff;;
}
.LINE .rce-mbox{
    background-color: #ccffcc;
}
.LINE .rce-mbox-right-notch, .LINE .rce-mbox-left-notch{
    fill: #ccffcc;;
}
.FACEBOOK .rce-mbox{
    background-color: #d3dcf5;
}
.FACEBOOK .rce-mbox-right-notch, .FACEBOOK .rce-mbox-left-notch{
    fill: #d3dcf5;;
}
.EMAIL .rce-mbox{
    background-color: #ffebd4;
}
.EMAIL .rce-mbox-right-notch, .EMAIL .rce-mbox-left-notch{
    fill: #ffebd4;;
} /* END 動態設置訊息框樣式 */

/* @media screen and (max-width: 768px) {
    #Customer .rce-button-icon--container{
        display: flex;
        flex-direction: column;
    }  
} */
