h6{
    font-weight: 600;
    margin-bottom: 5px;
}
.userDetailSec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 18px 0;
}
.userDetailTitleSec{
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
    width: 100%;
    /* min-width: 260px; */
    padding: 18px;
    background-color: #001529;
    border-left: 1px solid white;
    box-shadow: 2px 5px 10px -5px #33333366;
    /* height: 61px; */
}
.userDetailH6{
    margin: 0;
    width: 100%;
    color: #FFF;
    font-size: 1rem;
}
.userDetailDiv{
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px;
}
/* .userDetailUserNumberDiv{
    background-color: #001529BB;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 5px;
} */
.userDetailTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userDetailDivAvatar{
    width: 100px;
}
.userDetailAvatarImg{
    width: 100%;
}
.userDetailSubTitle{
    font-weight: 600;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.userDetailwpId{
    justify-content: center;
}
.userDetailSubTitle span{
    font-weight: 400;
}
.userDetailChannelContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.userDetailChannel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    margin: 3px;
    padding: 5px;
    border-radius: 5px;
}
.userDetailChannelDetailContainer, .userDetailTagContainer, .userDetailTagDetailContainer{
    display: flex;
    flex-direction: column;
}
.userDetailTagContainer{
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.userDetailTagDetailContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
/* .userDetailDivTag{
    display: flex;
    align-items: flex-start;
    justify-content: center;
} */
.userDetailChannelDetail, .userDetailTag, .userDetailSettingTag{
    min-width: 250px;
    max-width: 260px;
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.userDetailSettingTag{
    width: 50%;
}
/* .userDetailChannelDetail .userDetailSubTitle:nth-child(1), .userDetailTag .userDetailSubTitle:nth-child(1), .userDetailSettingTag .userDetailSubTitle:nth-child(1){
    width: 100%;
    text-align: center;
} */
/* .userDetailSubTitle.userDetailSubTitleName{
    justify-content: center;
} */
.userDetailSubTitleName{
    margin: 1px auto;
    text-align: center;
}
.userDetailSubTitleName span{
    font-weight: 600;
    font-size: 15px;
}
.userDetailTagIconDiv{
    /* margin: 4px 1px; */
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.userDetailTagIconDiv span{
    margin: 4px;
}
.userDetailTagIcon{
    padding: 2px 5px;
    font-size: 14px;
}
.userDetailDivTags .userDetailSubTitle span{
    font-size: 12px;
}
.userDetailTitleTag{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.userDetailTitleTag h6{
    margin-bottom: 0;
}
.userDetailTitleTagH6{
    margin-bottom: 5px!important;
    width: 100%;
}
.userDetailTitleTagsRecentRemove{
    margin-left: 5px;
}
.userDetailTags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 4px;
    font-size: 14px;
}
.userDetailTags span[role="img"]{
    color: #1d39c488;
}
.userDetailTags span[role="img"]:hover{
    color: #1d39c4;
}
.userDetailTag{
    width: fit-content;
    min-width: initial;
    max-width: none;
    padding: 4px 8px;
    align-items: center;
    background-color: #33658A;
    color:#FFF;
}
.userDetailNoTags{
    align-items: center;
    background-color: #6ea2c9;
    color:#FFF;
}
.userDetailSettingTag{
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
.userDetailTagButtonDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.userDetailTagButton{
    margin: 5px;
    padding: 2px 5px;
}
@media screen and (max-width: 767px) {
    .userDetailTitleSec{
        min-width: 260px;
        height: 50px;
        padding: 8px;
    }
    .userDetailH6{
        margin-left: 10%;
        text-align: left;
        font-size: 14px;
    }
    .userDetailTagDetailContainer{
        width: 100%;
    }
}