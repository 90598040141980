.lineMessagesLayout{
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
    border-radius: 3px;
    /* max-height: 92vh; */
}
.lineMessagesOuterContent{
    display: flex;
    /* align-items: center; */
    overflow-y: hidden!important;
    justify-content: center;
}
.lineMessagesContent{
    /* height: 100%; */ 
    /* margin: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    /* overflow-y: scroll!important;
    -ms-overflow-style: none!important; 
    overflow: -moz-scrollbars-none!important; 
    scrollbar-width: none!important;   */
}
.lineMessagesContentTable{
    align-items: flex-start;
}
.lineMessagesContent h1, .lineMessagesContent h2, .lineMessagesContent h3, .lineMessagesContent h4, .lineMessagesContent h5, .lineMessagesContent h6{
    margin-bottom: .6em;
}
.lineMessagesContent div, .lineMessagesContent span, .lineMessagesContent p {
    font-size: 16px;
}
.lineMessagesTabs{
    margin: 0;
}
.lineMessagesTable{
    width: 100%;
    max-width: 1024px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.lineMessagesTable div{
    width: 100%;
}
.lineMessagesSearchSec{
    width: 80%;
    max-width: 1024px;
    /* height: 100%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* max-height: 78vh;
    overflow-y: scroll; */
}
.lineMessagesSearchDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.lineMessagesSearch{
    width: 100%;
    margin: 1% 0 1.2% 2.5%;
}
.lineMessagesSearchTitle{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.lineMessagesSearch svg{
    display: flex;
    align-items: center;
}
.lineMessagesList{
    width: 100%;
}
.lineMessagesListItemMeta{
    flex-wrap: wrap;
}
.lineMessagesInputSpace{
    margin: 0.5em;
}
.lineMessagesDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.lineMessagesSeeIconDiv{
    display: flex;
    flex-direction: row;
}
.lineMessagesSeeIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.lineMessagesSeeIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.lineMessagesDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.lineMessagesDescriptionItemName {
    width: 200px;
}
.lineMessagesDescriptionItemCategory{
    width: 235px;
}
.lineMessagesDescriptionItemDescription{
    width: 320px;
}
.lineMessagesDescriptionItemPriority{
    width: 125px;
}
.lineMessagesDescriptionItemUpdateBtn{
    width: 100%;
}
.lineMessagesDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.lineMessagesDescriptionLabel{
    font-weight: 800;
    color: #777;
}
.lineMessagesDescriptionLabelName, .lineMessagesDescriptionLabelCategory, .lineMessagesDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.lineMessagesDescriptionLabelPriority{
    /* flex: 4; */
    width: 70px;
}
.lineMessagesDescriptionInputName{
    /* flex: 2; */
    width: 100px;
}
.lineMessagesDescriptionInputCategory{
    /* flex: 3; */
    width: 135px;
}
.lineMessagesDescriptionInputDescription{
    /* flex: 4; */
    width: 220px;
}
.lineMessagesDescriptionInputPriority{
    /* flex: 0.5; */
    width: 55px;
}
@media screen and (max-width: 767px) {
    .lineMessagesContent div, .lineMessagesContent span, .lineMessagesContent p {
        font-size: 14px;
    }
    .lineMessagesSearchSec{
        width: 100%;
    }
    .lineMessagesSearch{
        width: 70%;
        min-width: 280px;
    }
    .lineMessagesDescriptionDiv{
        flex-direction: column;
    }
    .lineMessagesDescriptionItemDescription{
        width: 260px;
    }
    .lineMessagesDescriptionInputDescription{
        width: 160px;
    }
    .lineMessagesDescriptionItem{
        width: 100%;
        margin: 6px;
    }
    .lineMessagesDescriptionItemTitle{
        flex-direction: column;
    }
    .lineMessagesAddIcon{
        margin: 5px!important;
    }
}