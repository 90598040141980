#Customer .rce-container-mbox {
    min-width: initial;
}
#Customer .rce-container-input{
    min-width: 100%;
}
#Customer .rce-mbox-video--video{
    /* margin-top: -14px; */
    border-radius: 0;
}
#Customer .rce-mbox-video--img{
    background: #000;
}
#Customer .rce-dropdown{
    min-width: 10%;
}
#Customer .rce-input{
    height: 82px;
    z-index: 5;
    margin-right: 25px;
}
#Customer .rce-input-buttons{
    margin: 3px;
    justify-content: flex-end;
}
#Customer .rce-button{
    border-radius: 5px;
}
/* File訊息區塊最大寬度 */
#Customer .rce-mbox-file>button {
    max-width: 313px!important;
}
@media screen and (max-width:767px){
    .CHAT.left, .LINE.left, .FFACEBOOK.left, .EMAIL.left{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .CHAT.right, .LINE.right, .FFACEBOOK.right, .EMAIL.right{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    #Customer .rce-container-mbox {
        width: fit-content;
    }
    #Customer .rce-mbox-audio {
        max-width: 220px;
    }
    #Customer .rce-mbox-audio audio{
        max-width: 220px;
    }
    /* File訊息區塊最大寬度 */
    #Customer .rce-mbox-file>button {
        max-width: 232px!important;
    }
}
@media screen and (max-width:576px){
    #Customer .rce-container-input{
        /* min-width: 90%; */
        min-width: initial;
    }
    #Customer .rce-input{
        width: 100%;
        margin-right: 28px;
        flex: 2;
        z-index: 5;
    }
    #Customer .rce-input-buttons{
        width: 100%;
        flex: 1.3;
    }
}
