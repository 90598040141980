.lineCastInterfaceEmojiDropdownDiv{
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.lineCastInterfaceShowEmojiModalBtn{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    z-index: 20;
}
.lineCastInterfaceShowEmojiModalBtn::after{
    content: "🙂";
}
.lineCastInterfaceShowEmojiModalBtn:hover::after{
    content: "😀";
}
/* emoji modal位置 */
.lineCastInterfaceEmojiModal{
    top: 4%!important;
    right: -1.5%;
}
.lineCastInterfaceDiv{
    width: 98%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* outline: 1px solid #888;
    border-radius: 3px; */
}
.lineCastInterfaceForm{
    width: 100%;
    /* margin-top: -22px; */
    /* margin-top: -65px; */
}
.lineCastInterfaceDropdown{
    /* margin-bottom: 8px; */
    display: flex;
    justify-content: flex-end;
}
.lineCastInterfaceDropdown button{
    z-index: 20;
    background-color: #1677FE!important;
    border-radius: 5px;
}
.lineCastInterfaceDropdown button:hover{
    background-color: #4592FE!important;
    opacity: 1;
}
.lineCastInterfaceInput{
    /* height: 84px; */
    height: 174px;
    border-radius: 2px;
}
.lineCastInterfaceButtonDiv{
    display: flex;
    flex-direction: row;
}
.lineCastInterfaceButton{
    background-color: rgb(22, 119, 254);
    color: white;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
    outline: none;
    padding: 8px;
    height: initial;
    position: relative;
    text-align: center;
    transition: all .15s ease;
    -webkit-user-select: none;
    user-select: none;
}
.lineCastInterfaceUploadButton{
    background-color: rgb(22, 119, 254);
    color: white;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
    outline: none;
    padding: 4px 8px;
    height: initial;
    position: relative;
    text-align: center;
    transition: all .15s ease;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
}
.lineCastInterfaceButton:hover{
    background-color: #4592FE;
    color: white!important;
}
.chatRoomUploadLabel{
    margin: 0 0 2px 5px;
    background-color: rgb(22, 119, 254);
    color: white;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
    outline: none;
    padding: 8px;
    position: relative;
    text-align: center;
    transition: all .15s ease;
    -webkit-user-select: none;
    user-select: none;    
}
.chatRoomUploadText{
    font-size: 14px;
}
.chatRoomUploadInput{
    display: none;
}
.lineCastInterfaceUpload{
    display: inline;
}
/* 下方為測試元件 */
.lineCastInterfaceBtn{
    height: 50px;
    border-radius: 3px;
}
.lineCastInterfaceBtn textarea{
    height: 50px!important;
    overflow: scroll!important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none; /* Old Firefox */
    scrollbar-width: none;  /* Firefox */
}
.lineCastInterfaceBtn textarea::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.lineCastInterfaceSec{
    width: 100%;
    max-width: 540px;
    padding: 20px 16px;
    /* padding: 10px 5px; */
    background-color: #f5f5f5;
    box-shadow: 1px 1px 8px 0px #33333366;
}

@media screen and (max-width: 1510px) {
    .lineCastInterfaceEmojiModal{
        top: 10%!important;
        right: -1.5%;
    }
}
@media screen and (max-width: 1313px) {
    .lineCastInterfaceEmojiModal{
        top: 10%!important;
        right: -1.5%;
    }
}
@media screen and (max-width: 1024px) {
    .lineCastInterfaceEmojiModal{
        top: 0%!important;
    }
}
@media screen and (max-width: 991px) {
    .lineCastInterfaceEmojiModal{
        /* top: 9.5%!important; */
        top: 16.5%!important;
        right: -4.5%;
    }
}
@media screen and (max-width: 767px) {
    .lineCastInterfaceEmojiModal{
        top: 10%!important;
        right: 0%;
    }
}