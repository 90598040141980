.usersAddLayout{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
}
.usersAddOuterContent{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    overflow-y: hidden!important;
}
.usersAddContent{
    /* height: 100%; */ 
    margin-bottom: 2em!important;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.usersAddContent h1, .usersAddContent h2, .usersAddContent h3, .usersAddContent h4, .usersAddContent h5, .usersAddContent h6{
    margin-bottom: .6em;
}
.usersAddTitle h4{
    font-weight: 800;
    color: #001529;
}
/* .usersAddContentQuery{
    padding-top: 2em;
    border-top: 1px dashed #888!important;
} */
.usersAddDivider{
    padding-top: 2em;
    border-top: 2px dashed #001529cc!important;
    width: 70%;
    margin: auto;
}
.usersAddContentQuery h5{
    /* margin-bottom: 0; */
    color: #1677ff;
    font-weight: 800;
}
.usersAddContent div, .usersAddContent span, .usersAddContent p {
    font-size: 18px;
}
.usersAddList{
    width: 100%;
    border: 1px solid #1677ff;
    border-radius: 5px;
    padding: 10px;
}
.usersAddList span{
    color: #1677ff;
}
.usersAddListItemMeta{
    flex-wrap: wrap;
}
.usersAddInputSpace{
    margin: 0.5em;
}
.usersAddUserDescriptionDiv{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}
.usersAddUserDescriptionItem{
    margin: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.usersAddUserDescriptionText{
    font-weight: 400;
}
.usersAddUserDescriptionItemName {
    width: 260px;
}
.usersAddUserDescriptionItemCategory{
    width: 320px;
}
.usersAddUserDescriptionItemDescription{
    width: 380px;
}
.usersAddUserDescriptionItemPriority{
    width: 145px;
}
.usersAddUserDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.usersAddUserDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.usersAddUserDescriptionLabel{
    font-weight: 800;
    color: #001529cc;
}
.usersAddUserDescriptionLabelName, .usersAddUserDescriptionLabelCategory, .usersAddUserDescriptionLabelDescription{
    /* flex: 5; */
    width: 120px;
}
.usersAddUserDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.usersAddUserDescriptionInputName{
    /* flex: 2; */
    width: 140px;
}
.usersAddUserDescriptionInputCategory{
    /* flex: 3; */
    width: 200px;
}
.usersAddUserDescriptionInputDescription{
    /* flex: 4; */
    width: 260px;
}
.usersAddUserDescriptionInputPriority{
    /* flex: 0.5; */
    width: 60px;
}
.usersAddUserDescriptionInputAddBtn{
    padding: 4px 15px;
}
.usersAddUserDescriptionInputAddBtn span{
    font-size: 16px;
    line-height: initial;
}
.usersAddContent{
    /* margin-top: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
.usersAddSearchSec{
    width: 65%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.usersAddList{
    width: 100%;
}
.usersAddListItemMeta{
    flex-direction: column;
    /* flex-wrap: wrap; */
}
.usersAddUserDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.usersAddUserDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.usersAddUserDescriptionQueryDiv{
    flex-direction: row;
}
@media screen and (max-width: 767px) {
    .usersAddContent div, .usersAddContent span, .usersAddContent p {
        font-size: 14px;
    }
    .usersAddDivider{
        width: 95%;
    }
    .usersAddContentQuery h5{
        font-size: 18px;
    }
    .usersAddList{
        padding: 0px 8px;
    }
    .usersAddSearchSec{
        width: 100%;
    }
    .usersAddUserDescriptionDiv{
        width: 100%;
    }
    .usersAddUserDescriptionItem {
        margin: 8px 0;
        width: 100%;
        justify-content: flex-start;
    }
    .usersAddUserDescriptionItemUpdateBtn{
        justify-content: center;
    }
    .usersAddUserDescriptionLabelName{
        /* width: 42%; */
        width: fit-content;
    }
    .usersAddUserDescriptionLabelCategory{
        /* width: 33%; */
        width: fit-content;
    }
    .usersAddUserDescriptionLabelDescription{
        /* width: 28%; */
        width: fit-content;
    }
    .usersAddUserDescriptionLabelPriority{
        width: 58%;
        width: fit-content;
    }
    .usersAddUserDescriptionInputName{
        /* flex: 2; */
        width: 58%;
    }
    .usersAddUserDescriptionInputCategory{
        /* flex: 3; */
        width: 67%;
    }
    .usersAddUserDescriptionInputDescription{
        /* flex: 4; */
        width: 72%;
    }
    .usersAddUserDescriptionInputPriority{
        /* flex: 0.5; */
        width: 15%;
    }
    .usersAddUserDescriptionItemTitle{
        flex-direction: column;
    }
    .usersAddUserDescriptionDiv{
        flex-direction: column;
    }
}