/* Dashbord高度 */
#admin-main>.ant-layout{
    height: 92vh;
    min-height: 92vh!important;
}
/* .ant-layout{
    min-height: 100vh!important;
} */
/* Sider左側內容區塊 */
.ant-layout-content{
    margin: 0!important;
}
#Users.ant-layout-content, #Tags.ant-layout-content, #ChatRoom.ant-layout-content, 
#LINE.ant-layout-content, #Facebook.ant-layout-content, #Email.ant-layout-content, #Map.ant-layout-content{
    height: 100%;
    /* height: 1000px; */
}
/* :where(.css-dev-only-do-not-override-mxhywb).ant-layout{
    background-color: initial;
} */
/* Sider縮合鍵置於容器內 */
:where(.css-dev-only-do-not-override-mxhywb).ant-layout .ant-layout-sider-trigger{
    position: relative;
}
/* Sider選項置中 */
.ant-menu-item{
    display: flex!important;
    align-items: center;
}
/* Sider縮合時Icon左右邊距 */
:where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline-collapsed >.ant-menu-item, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-mxhywb).ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title {
    padding-inline: calc(50% - 1.15em);
}
/* Icon大小 */
.ant-menu-item .ant-menu-item-icon, .ant-menu .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu .ant-menu-item .anticon, .ant-menu .ant-menu-submenu-title .anticon {
    min-width: 1em!important;
}
/* Icon被選擇時 */
li.ant-menu-item.ant-menu-item-selected {
    display: flex;
    align-items: center;
}
@media screen and (max-width:1510px) {
    /* Dashbord高度 */
    #admin-main>.ant-layout{
        height: 100vh;
    }
}
@media screen and (max-width:991px) {
    #admin-main{
        width: 100%;
        margin: 0;
    }
    #admin-main>.ant-layout{
        height: 100vh;
        /* height: initial; */
        /* min-height: 100vh!important; */
    }
    #Users.ant-layout-content, #Tags.ant-layout-content, #ChatRoom.ant-layout-content, 
    #LINE.ant-layout-content, #Facebook.ant-layout-content, #Email.ant-layout-content, #Map.ant-layout-content{
        overflow-y: scroll!important;
        -ms-overflow-style: none!important; 
        overflow: -moz-scrollbars-none!important; 
        scrollbar-width: none!important;  
    }
}
@media screen and (max-width:767px) {
    /* Dashbord高度 */
    /* .ant-layout{
        min-height: 100vh!important;
    } */
    #admin-main{
        width: 100%;
        margin: 1px 1px;
    }  
    #admin-main .ant-layout-content{
        padding: 0 1px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        overflow: -moz-scrollbars-none; /* Old Firefox */
        scrollbar-width: none;  /* Firefox */
    }   
    #admin-main .ant-layout-content::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}
