.userDetailChannelChat{
    border: 1px solid #1E8C96;
    color: #1E8C96;
    background-color: #fafafa;
}
.userDetailChannelLine{
    background-color: #00B900;
}
.userDetailChannelFb{
    background-color: #4267B2;
}
.userDetailChannelEmail{
    background-color: #EA4335;
}
.userDetailChannelDetailChat{
    border-color: #1E8C96;
    color:#1E8C96;
}
.userDetailChannelDetailLine{
    border-color: #00B900;
    color:#00B900;
}
.userDetailChannelDetailFb{
    border-color: #4267B2;
    color:#4267B2;
}
.userDetailChannelDetailEmail{
    border-color: #EA4335;
    color:#EA4335;
}