/* 按鈕右邊界 */
/* .loginButton{
    margin-right: 1em;
} */

/* 給AutoPopup使用，提示標題樣式 */
.modalTitle{
    width: 100%;
    margin-left: 1.2em;
    text-align: center;
    font-size: 1.2em;
    color: #FA9600;
}
/* Popup 整個內容區塊 */
.modal {
    background-color: white;
    border-radius: 3px;
}


/* Popup Header層 */
.modalHeader{
    /* padding: 8px; */
    border-width: 0;
    border: none !important;
    width: 100%;
    border-radius: 3px;
}
.modalClose{
    position: absolute;
    font-weight: 700;
    color: white;
    font-size: 20px;
    top: -25px;
    cursor: pointer;
}
.PopupBanner{
    border-radius: 3px;
    width: 100%;
    height: 100%;

}
.PopupBanner img{
    border-radius: 3px;
    width: 100%;
    height: 100%;
}
.modalBody{
    background-color: white;
}
.modalBodyLogout{
    padding: 20px;
    text-align: center;
}
.KidProLoginTitleBox{
    display: flex;
    justify-content: center;
}
.KidProLoginTitle{
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #1E8C96;
    font-weight: bold;
}
.loginButton{
    background-color: #1E8C96;
    border: none!important;
    width: 90%;
    color: white;
    padding: 0.5em;
    border-radius: 3px;
    /* transition: 30ms; */
}
.loginButton:hover{
    background-color: #1E8C96;
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
.loginButton:active{
    background-color: #1E8C96;
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
/* Popup 按鈕 - 登入/註冊文字 */
.loginText {
    color: #eee;
}
.loginText:active {
    color: #eee;
}
@media screen and (max-width:540px) {
    .KidProLoginTitleBox{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .KidProLoginTitle {
        font-size: 10vw;
        flex: 5;
        text-align: right;
        margin-top: 2px;
    }
}


