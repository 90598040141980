.chatRoomContentDiv{
    width: 98%;
    margin: auto;
    display: flex;
    outline: 1px solid #888;
    border-radius: 3px;
}
.chatRoomContentBtn{
    height: 50px;
}
/* .chatRoomContentMessageBoxVideo .rce-mbox-video{
    margin-top: -14px;
} */