.emojiModalDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.showEmojiModalBtn{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    z-index: 20;
}
.showEmojiModalBtn::after{
    /* content: "🙂"; */
    content: url('./happiness.png');
    transform: scale(.04);
}
.showEmojiModalBtn:hover::after{
    /* content: "😀"; */
    content: url('./happy.png');
    transform: scale(.04);
}
/* emoji modal位置 */
.emojiModal{
    top: 4%!important;
    right: -1.5%;
    z-index: 30;
}