.tagsAddLayout{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    overflow-y: hidden!important;
}
.tagsAddOuterContent{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    overflow-y: hidden!important;
}
.tagsAddContent{
    /* height: 100%; */ 
    margin-bottom: 2em!important;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tagsAddContent h1, .tagsAddContent h2, .tagsAddContent h3, .tagsAddContent h4, .tagsAddContent h5, .tagsAddContent h6{
    margin-bottom: .6em;
}
.tagsAddTitle h4{
    font-weight: 800;
    color: #001529;
}
/* .tagsAddContentQuery{
    padding-top: 2em;
    border-top: 1px dashed #888!important;
} */
.tagsAddDivider{
    padding-top: 2em;
    border-top: 2px dashed #001529cc!important;
    width: 70%;
    margin: auto;
}
.tagsAddContentQuery h5{
    /* margin-bottom: 0; */
    /* color: #1677ff; */
    font-weight: 800;
}
.tagsAddContent div, .tagsAddContent span, .tagsAddContent p {
    font-size: 16px;
}
.tagsAddList{
    width: 100%;
    /* border: 1px solid #1677ff; */
    border-radius: 5px;
    padding: 10px;
}
.tagsAddList span{
    /* color: #1677ff; */
}
.tagsAddListItemMeta{
    flex-wrap: wrap;
}
/* .tagsAddTagIcon{
    padding: 4px 8px;
} */
.tagsAddInputSpace{
    margin: 0.5em;
}
.tagsAddTagDescriptionDiv{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}
.tagsAddTagDescriptionItem{
    margin: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.tagsAddTagDescriptionText{
    font-weight: 400;
}
.tagsAddTagDescriptionItemName {
    width: 240px;
}
.tagsAddTagDescriptionItemCategory{
    width: 300px;
}
.tagsAddTagDescriptionItemDescription{
    width: 360px;
}
.tagsAddTagDescriptionItemPriority{
    width: 145px;
}
.tagsAddTagDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.tagsAddTagDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.tagsAddTagDescriptionLabel{
    font-weight: 800;
    color: #001529cc;
}
.tagsAddTagDescriptionLabelName, .tagsAddTagDescriptionLabelCategory, .tagsAddTagDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.tagsAddTagDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.tagsAddTagDescriptionInputName{
    /* flex: 2; */
    width: 140px;
}
.tagsAddTagDescriptionInputCategory{
    /* flex: 3; */
    width: 200px;
}
.tagsAddTagDescriptionInputDescription{
    /* flex: 4; */
    width: 260px;
}
.tagsAddTagDescriptionInputPriority{
    /* flex: 0.5; */
    width: 60px;
}
.tagsAddTagDescriptionInputAddBtn{
    padding: 4px 15px;
}
.tagsAddTagDescriptionInputAddBtn span{
    font-size: 16px;
    line-height: initial;
}
.tagsAddContent{
    /* margin-top: 2em!important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
.tagsAddSearchSec{
    width: 65%;
    margin: 10px auto;
    padding: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 3px -1px 10px -1px #00152933;
    border-radius: 3px;
}
.tagsAddList{
    width: 100%;
}
.tagsAddListItemMeta{
    flex-direction: column;
    /* flex-wrap: wrap; */
}
.tagsAddTagDescriptionItem{
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
/* .tagsAddTagDescriptionDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
} */
.tagsAddTagDescriptionQueryDiv{
    flex-direction: row;
}
@media screen and (max-width: 767px) {
    .tagsAddContent div, .tagsAddContent span, .tagsAddContent p {
        font-size: 14px;
    }
    .tagsAddDivider{
        width: 95%;
    }
    .tagsAddContentQuery h5{
        font-size: 18px;
    }
    .tagsAddList{
        padding: 0px 8px;
    }
    .tagsAddSearchSec{
        width: 100%;
    }
    .tagsAddTagDescriptionDiv{
        width: 100%;
        flex-direction: column;
    }
    .tagsAddTagDescriptionItem {
        margin: 8px 0;
        width: 100%;
        justify-content: flex-start;
    }
    .tagsAddTagDescriptionItemUpdateBtn{
        justify-content: center;
    }
    .tagsAddTagDescriptionLabelName{
        /* width: 42%; */
        width: fit-content;
    }
    .tagsAddTagDescriptionLabelCategory{
        /* width: 33%; */
        width: fit-content;
    }
    .tagsAddTagDescriptionLabelDescription{
        /* width: 28%; */
        width: fit-content;
    }
    .tagsAddTagDescriptionLabelPriority{
        width: 58%;
        width: fit-content;
    }
    .tagsAddTagDescriptionInputName{
        /* flex: 2; */
        width: 58%;
    }
    .tagsAddTagDescriptionInputCategory{
        /* flex: 3; */
        width: 67%;
    }
    .tagsAddTagDescriptionInputDescription{
        /* flex: 4; */
        width: 72%;
    }
    .tagsAddTagDescriptionInputPriority{
        /* flex: 0.5; */
        width: 15%;
    }
    .tagsAddTagDescriptionItemTitle{
        flex-direction: column;
    }
    .tagsAddSearchSec{
        padding: 18px 12px;
    }
}