.chatRoomContainerAssignSec{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
    padding: 8px;
    background-color: #001529;
    box-shadow: 2px 5px 10px -5px #33333366;
}
.chatRoomContainerContentSec{
    background-color: #FAFAFA;
    width: 100%;
    min-height: 719px;
    padding: 10px;
}
.chatRoomContainerSendSec{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
    width: 100%;
    /* padding: 16px 12px; */
    padding: 10px 5px;
    background-color: #001529;
    box-shadow: 2px -5px 10px -5px #33333366;
}
@media screen and (max-width: 1510px) {
    .chatRoomContainerContentSec{
        min-height: 733px;
    }
}
@media screen and (max-width: 991px) {
    .chatRoomContainerContentSec{
        min-height: 300px;
    }
}
@media screen and (max-width: 767px) {
    .chatRoomContainerAssignSec{
        min-width: 260px;
        padding: 0 8px;
    }
    .chatRoomContainerContentSec{
        min-height: 200px;
        height: 100%;
        border-left: initial;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;
    }
}

